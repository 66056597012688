import React from 'react';
import styles from '../home.module.css';
import Text from '../../../components/text';
import Button from '../../../components/button';
import {
  addEllipsisToText,
  formatMandateDate,
  formattedCurrency,
} from '../../../utils/utilsMethods';
import { useAppDispatch } from '../../../hooks/redux';
import { IMandateHistoryContent } from '../../../interface/mandate';
import { openModal } from '../../../redux/features/modalSlice';
import { MODAL_TYPES } from '../../../utils/constants';

export interface IProps {
  transaction: IMandateHistoryContent;
}

export const RecentCollectionHeader = () => {
  return (
    <div className={styles.recentCollectionWrapper}>
      <div className="col-3">
        <Text color="#353F50" text={'Reference'} size={1} bold />
      </div>
      <div className="col-3 col-lg-3 col-md-2">
        <Text color="#353F50" text={'Amount'} size={1} bold />
      </div>
      <div className="col-2">
        <Text text={'Status'} color="#353F50" size={1} bold />
      </div>

      <div className="col-2 d-none d-md-block">
        <Text color="#353F50" text={'Date Created'} size={1} bold />
      </div>
      <div className="col-2 text-center">
        <Button className="opacity-0 " label="View" type="link" />
      </div>
    </div>
  );
};

const RecentCollection = ({ transaction }: IProps) => {
  const { amount, mandateReference, time, transactionResponse } = transaction;
  const dispatch = useAppDispatch();
  const handleOpenTransactionReceipt = (
    modalType: string,
    transactionType: string = 'success',
    transactionDetails: IMandateHistoryContent
  ) => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: modalType,
        modalProps: {
          title: 'Transaction Receipt',
          size: 'lg',
          transactionType,
          transactionDetails: { ...transactionDetails },
        },
      })
    );
  };

  return (
    <div className={styles.recentCollectionWrapper}>
      <div className="col-3">
        <Text
          color="#353F50"
          text={addEllipsisToText(mandateReference, 10)}
          size={1}
          bold
          className="d-none d-md-block"
        />
        <Text
          color="#353F50"
          text={addEllipsisToText(mandateReference, 6)}
          size={1}
          bold
          className="d-block d-md-none"
        />
      </div>
      <div className="col-3 col-lg-3 col-md-2">
        <Text
          color="#353F50"
          text={formattedCurrency(Number(amount))}
          size={1}
        />
      </div>
      <div className="col-2">
        <Text
          text={transactionResponse !== '99' ? 'Approved' : 'Rejected'}
          color={
            transactionResponse.toLowerCase().includes('00')
              ? 'var( --Supportive-Roshi-100)'
              : transactionResponse.toLowerCase().includes('99')
                ? 'var(--error)'
                : '#000000'
          }
          size={1}
          bold
        />
      </div>

      <div className="col-2 d-none d-md-block">
        <Text
          color="#353F50"
          text={formatMandateDate(time, 'dd/MM/yyyy')}
          size={1}
        />
      </div>
      <div className="col-2 ">
        {/* <Link
          to={mandateDetailsLink + '/' + reference}
          className=""
          style={{ textDecoration: 'none' }}
        > */}
        <Button
          label="View"
          type="link"
          onClick={() =>
            handleOpenTransactionReceipt(
              MODAL_TYPES.TRANSACTION_RECEIPT,
              transactionResponse == '00' ? 'success' : 'failed',
              transaction
            )
          }
        />
        {/* </Link> */}
      </div>
    </div>
  );
};

export default RecentCollection;
