import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IBatchMandate,
  ICreateBulkMandateList,
  ICreateMandateRequest,
  // ICreateBulkMandateList,
  IMandateAnalyticsResponse,
  ISelectableMandateList,
} from '../../interface/mandate';
import { RootState } from '../store';

interface IMandateSlice {
  loading: boolean;
  mandates: ISelectableMandateList[];
  searchableString: string;
  mandateStat: IMandateAnalyticsResponse['data']['piechart'] | null;
  isBulk: boolean;
  batchList: Array<IBatchMandate>;
  totalElements: number;
  totalPages: number;
  recentTransactions: Array<{
    status: string;
    date: string;
    amount: string;
    reference: string;
  }>;
  bulkMandate: {
    batchReference: string;
    batchList: ICreateBulkMandateList[];
    totalElements: number;
    totalPages: number;
    currentPage: number;
    // gotoNext: () => void;
    // gotoPage: (page: number) => void;
    // gotoPreviousPage: () => void;
  };
  createMandateData: ICreateMandateRequest | null
}

const initialState: IMandateSlice = {
  loading: false,
  mandates: [],
  searchableString: '',
  mandateStat: null,
  isBulk: false,
  batchList: [],
  totalElements: 0,
  totalPages: 0,
  recentTransactions: [],
  bulkMandate: {
    batchList: [],
    batchReference: '',
    currentPage: 0,
    totalElements: 0,
    totalPages: 0,
  },
  createMandateData: null
};

export const mandateSlice = createSlice({
  name: 'mandates',
  initialState,
  reducers: {
    setSelectBulkMandateToBeCreated: (state, actions) => {
      state.bulkMandate.batchList = state.bulkMandate.batchList?.map(
        (item) => ({
          ...item,
          isSelected: actions.payload,
        })
      );
    },
    selectAMandateFromBulkMandateCreation: (state, actions) => {
      state.bulkMandate.batchList = state.bulkMandate.batchList?.map((item) =>
        item.reference === actions?.payload
          ? { ...item, isSelected: !item.isSelected }
          : { ...item }
      );
    },
    setCreateBulkMandate: (
      state,
      actions: PayloadAction<
        Pick<
          IMandateSlice['bulkMandate'],
          'batchList' | 'batchReference' | 'totalElements' | 'totalPages'
        >
      >
    ) => {
      state.bulkMandate.batchList = actions.payload.batchList;
      state.bulkMandate.batchReference = actions.payload.batchReference;
      state.bulkMandate.totalElements = actions.payload.totalElements;
      state.bulkMandate.totalPages = actions.payload.totalPages;
    },
    setGotoNextBulkPage: (state) => {
      if (
        state.bulkMandate.totalPages &&
        state.bulkMandate.currentPage < state.bulkMandate.totalPages
      ) {
        state.bulkMandate.currentPage = state.bulkMandate.currentPage + 1;
      }
    },
    setGotoPreviousBulkPage: (state) => {
      if (state.bulkMandate.totalPages && state.bulkMandate.currentPage > 0) {
        state.bulkMandate.currentPage = state.bulkMandate.currentPage - 1;
      }
    },
    setGotoBulkPage: (
      state,
      actions: PayloadAction<IMandateSlice['bulkMandate']['totalPages']>
    ) => {
      if (state.bulkMandate.totalPages) {
        state.bulkMandate.currentPage = actions.payload;
      }
    },
    setTotalPageAndElement: (
      state,
      actions: PayloadAction<
        Pick<IMandateSlice, 'totalElements' | 'totalPages'>
      >
    ) => {
      state.totalElements = actions.payload.totalElements;
      state.totalPages = actions.payload.totalPages;
    },
    setLoadingMandate: (
      state,
      actions: PayloadAction<IMandateSlice['loading']>
    ) => {
      state.loading = actions.payload;
    },
    setBatchList: (
      state,
      actions: PayloadAction<IMandateSlice['batchList']>
    ) => {
      state.batchList = actions.payload;
    },
    setMandateList: (
      state,
      actions: PayloadAction<IMandateSlice['mandates']>
    ) => {
      state.mandates = actions.payload;
    },

    selectMandate: (state, actions: PayloadAction<number>) => {
      state.mandates = state.mandates?.map((item) =>
        item.id === actions?.payload
          ? { ...item, selected: !item.selected }
          : { ...item }
      );
    },
    selectAllMandates: (state, actions) => {
      state.mandates = state.mandates?.map((item) => ({
        ...item,
        selected: actions.payload,
      }));
    },
    setHandleSearchList: (state, actions: PayloadAction<string>) => {
      state.searchableString = actions.payload;
    },
    setMandateStat: (
      state,
      actions: PayloadAction<IMandateSlice['mandateStat']>
    ) => {
      state.mandateStat = actions.payload;
    },

    setIsBulk: (state, actions: PayloadAction<IMandateSlice['isBulk']>) => {
      state.isBulk = actions.payload;
    },
    setRecentTransactionList: (
      state,
      actions: PayloadAction<IMandateSlice['mandates']>
    ) => {
      state.recentTransactions = actions.payload
        ?.slice(0, 5)
        .map(({ status, dateCreated, amount, mandateReference }) => ({
          status,
          date: dateCreated,
          amount,
          reference: mandateReference,
        }));
    },
    setCreateMandateData: (state, action: PayloadAction<IMandateSlice['createMandateData']>) => {
      if (action.payload) {
        state.createMandateData = action.payload;
        return
      }
      state.createMandateData = null;

    },
  },
});

export const {
  setLoadingMandate,
  setMandateList,
  selectMandate,
  selectAllMandates,
  setHandleSearchList,
  setMandateStat,
  setBatchList,
  setIsBulk,
  setTotalPageAndElement,
  setRecentTransactionList,
  setCreateBulkMandate,
  setGotoNextBulkPage,
  setGotoBulkPage,
  setGotoPreviousBulkPage,
  setSelectBulkMandateToBeCreated,
  selectAMandateFromBulkMandateCreation,
  setCreateMandateData,
} = mandateSlice.actions;

export const selectMandateState = (state: RootState) => state.mandates;

export default mandateSlice.reducer;
