import React from 'react';
import Text from '../../../components/text';
import styles from '../mandate.module.css';
import { Icon } from '../../../components/icons';
import { useNavigate } from 'react-router-dom';
import { MANDATE_SINGLEVIEW as MANDATE_SINGLE_VIEW } from '../../../routes/navigations';
import {
  addEllipsisToText,
  formatMandateDate,
  formattedCurrency,
} from '../../../utils/utilsMethods';
import { useAppSelector } from '../../../hooks/redux';
import Loader from '../../../components/Loader/Loader';
import MandatePagination from './MandatePagination';
import useFetchMandate from '../../../hooks/useFetchMandate';
import useRouteQuery from '../../../hooks/useRouteQuery';
import { ISelectableMandateList } from '../../../interface/mandate';
import EmptyState from '../../../components/EmptyState/EmptyState';

export const MANDATE_CREATION_STATUS = {
  '00': (
    <span className={`${styles.table_badge} ${styles.badge_active}`}>
      Approved
    </span>
  ),
  '99': (
    <span className={`${styles.table_badge} ${styles.badge_inactive}`}>
      Declined
    </span>
  ),
};

export const MANDATE_STATUS = {
  'Bank Approved': (
    <span className={`${styles.table_badge} ${styles.badge_active}`}>
      Approved
    </span>
  ),
  'Bank Disapproved': (
    <span className={`${styles.table_badge} ${styles.badge_inactive}`}>
      Declined
    </span>
  ),
  'Bank Rejected': (
    <span className={`${styles.table_badge} ${styles.badge_inactive}`}>
      Declined
    </span>
  ),
  'Biller Disapproved': (
    <span className={`${styles.table_badge} ${styles.badge_inactive}`}>
      Declined
    </span>
  ),
  'Bank Initiated': (
    <span className={`${styles.table_badge} ${styles.badge_pending}`}>
      Pending
    </span>
  ),
  NULL: <span className={`${styles.table_badge}`}>--</span>,
};

export const mandateDetailsLink = MANDATE_SINGLE_VIEW.split('/', 3).join('/');

const SingleMandateTable = () => {
  const { searchParams } = useRouteQuery();
  const navigate = useNavigate();
  const dateLabel = searchParams.get('dateLabel');
  const {
    loading: loadingMandates,
    mandates,
    totalPages,
  } = useAppSelector((state) => state.mandates);

  const { gotoNextPage, gotoPreviousPage, gotoPage, currentPage } =
    useFetchMandate(dateLabel!);

  const mandateList = mandates;

  const gotoDetailsPage = (mandate: ISelectableMandateList) => {
    navigate(mandateDetailsLink + '/' + mandate?.mandateReference, {
      state: { ...mandate },
    });
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr className={styles.table_header}>
              <th className="ps-4" scope="col">
                <div className="d-flex align-items-center">
                  <Text
                    text={'Ref'}
                    size={2}
                    className="ps-2"
                    style={{ width: 'max-content' }}
                    color={'var(--vfd-main-body-text)'}
                  />
                  <span className="ps-2">
                    <Icon name="downward_arrow" />
                  </span>
                </div>
              </th>
              <th scope="col" className="">
                <Text
                  text={'Customer Name'}
                  size={2}
                  className="ps-2"
                  style={{ width: 'max-content' }}
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Account Number'}
                  size={2}
                  className="ps-2"
                  style={{ width: 'max-content' }}
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Creation Status'}
                  size={2}
                  className="ps-2"
                  style={{ width: 'max-content' }}
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Mandate Amount'}
                  size={2}
                  className="ps-2"
                  style={{ width: 'max-content' }}
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col" className="">
                <Text
                  text={'Mandate Status'}
                  size={2}
                  className="ps-2"
                  style={{ width: 'max-content' }}
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col" className="">
                {' '}
                <Text
                  text={'Date Created'}
                  size={2}
                  className="ps-2"
                  style={{ width: 'max-content' }}
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Actions'}
                  size={2}
                  className="ps-2 invisible"
                  style={{ width: 'max-content' }}
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {!loadingMandates ? (
              mandateList?.map((mandate, index) => {
                const {
                  amount,
                  receiverAccountNo,
                  receiverAccountName,
                  dateCreated,
                  // selected,
                  status,
                  mandateStatusDescription,
                  mandateReference,
                  // id,
                } = mandate;

                return (
                  <tr className="" key={index}>
                    <td className="ps-4 py-3" scope="row">
                      <div className="d-flex align-items-center">
                        <Text
                          text={addEllipsisToText(mandateReference, 14)}
                          size={2}
                          className="ps-2"
                          style={{ width: 'max-content' }}
                          color={'var(--vfd-main-body-text)'}
                        />
                      </div>
                    </td>
                    <td className="py-3 ">
                      {' '}
                      <Text
                        text={addEllipsisToText(receiverAccountName, 14)}
                        size={2}
                        className="ps-2"
                        style={{ width: 'max-content' }}
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>
                    <td className="py-3">
                      {' '}
                      <Text
                        text={receiverAccountNo}
                        size={2}
                        className="ps-2"
                        style={{ width: 'max-content' }}
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>
                    <td className="py-3 ">
                      {
                        MANDATE_CREATION_STATUS[
                          status as keyof typeof MANDATE_CREATION_STATUS
                        ]
                      }
                    </td>
                    <td className="py-3">
                      {' '}
                      <Text
                        text={formattedCurrency(Number(amount))}
                        size={2}
                        className="ps-2"
                        style={{ width: 'max-content' }}
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>
                    <td className="py-3  ">
                      {!mandateStatusDescription ||
                      !mandateStatusDescription.length
                        ? MANDATE_STATUS['NULL']
                        : MANDATE_STATUS[
                            mandateStatusDescription as keyof typeof MANDATE_STATUS
                          ]}
                    </td>
                    <td className="py-3 ">
                      {' '}
                      <Text
                        text={formatMandateDate(dateCreated)}
                        size={2}
                        className="ps-2"
                        style={{ width: 'max-content' }}
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>
                    <td className="py-3">
                      <Text
                        text={'View'}
                        size={2}
                        className="px-2"
                        style={{ width: 'max-content', cursor: 'pointer' }}
                        color={'var(--blue-600)'}
                        onClick={() => gotoDetailsPage(mandate)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr style={{ textAlign: 'center' }}>
                <td colSpan={8} style={{ padding: '20px 0' }}>
                  <Loader />
                </td>
              </tr>
            )}

            {!loadingMandates && !mandateList.length ? (
              <tr style={{ textAlign: 'center' }}>
                <td colSpan={8} style={{ padding: '20px 0' }}>
                  <EmptyState />
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      {/* Pagination section */}
      <MandatePagination
        totalPages={totalPages as number}
        gotoNextPage={gotoNextPage}
        gotoPreviousPage={gotoPreviousPage}
        gotoPage={gotoPage}
        loading={loadingMandates}
        currentPage={currentPage}
      />
    </>
  );
};

export default SingleMandateTable;
