/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FormEvent, useRef, useState } from 'react';
import styles from './auth.module.css';
import Card from '../../components/card';
import Text from '../../components/text';
import TextInput from '../../components/textInput';
import { useValidation } from '../../hooks/useValidation';
import { loginValidation } from '../../utils/validations';
import Button from '../../components/button';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectAuthState, setAuthState } from '../../redux/features/authSlice';
import api from '../../network/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { IError } from '../../interface/error';
import useToast from '../../hooks/useToast';
import { setGlobalState } from '../../redux/features/globalSlice';

const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const state = useLocation().state as { from: string };
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const { data, addData, validated, validationResult } =
    useValidation(loginValidation);

  const timer = useRef<NodeJS.Timeout>();

  const [loading, setLoading] = useState(false);
  const [fromRoute] = useState<string>(() => state?.from);

  async function handleLogin(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setLoading(true);
    try {
      const loginData = {
        username: data.email as string,
        password: data.password as string,
      };
      const response = await api.login(loginData);
      const responseData = response.data;
      const token = responseData.accessToken;
      dispatch(setAuthState(token));
      dispatch(
        setGlobalState({
          email: responseData.email,
          receiverName: responseData.receiverName,
          receiverAccountNo: responseData.receiverAccountNo,
          companyLogo: responseData.companyLogo,
          accountBalance: responseData.accountBalance,
        })
      );
      if (fromRoute) {
        timer.current = setTimeout(() => {
          navigate(fromRoute);
        }, 500);
      }
    } catch (error: unknown) {
      const err = error as IError;
      toast.error(err.message || '');
    }
    setLoading(false);
  }

  function onInputChange(name: string, val: string) {
    addData(name, val);
  }

  return (
    <div className={styles.wrapper}>
      <div className="row">
        <div className="col-md-9 ps-lg-5">
          <Card className={styles.card_container}>
            {/* <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex gap-1">
                <Text
                  text="Don't have an account?"
                  color={'var(--vfd-main-body-text)'}
                  size={2}
                />
                <Text
                  text="Sign up"
                  size={2}
                  color={'var(--blue-600)'}
                  style={{ cursor: 'pointer' }}
                  // onClick={handleSignup}
                />
              </div>
            </div> */}
            <Text text="Login" size={8} bold color="var(--blue-600)" />
            <Text text="Enter details below to login" size={3} />
            <form onSubmit={handleLogin}>
              <TextInput
                label="Email"
                placeholder="Enter email address"
                id="email-login"
                name="email"
                type="text"
                onChange={onInputChange}
                errorText={validationResult.email}
                valid={!validationResult.email}
                // isRequired
              />
              <TextInput
                label="Password"
                placeholder="............."
                id="password-login"
                name="password"
                type="password"
                onChange={onInputChange}
                errorText={validationResult.password}
                valid={!validationResult.password}
                // isRequired
              />
              {/* <div
                className="d-flex justify-content-end align-items-center"
                style={{ height: '50px' }}
              >
                <Text
                  text="Forgot password?"
                  size={2}
                  color={'var(--blue-600)'}
                  style={{ cursor: 'pointer' }}
                  // onClick={handleSignup}
                />
              </div> */}

              <Button
                loading={loading}
                label="Login"
                type="primary"
                disabled={!validated}
                className="mt-4"
              />
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;
