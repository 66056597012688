export const HOME = '/';
export const LANDING = '/login';
export const REPORTS = '/reports';
export const CREATE_MANDATE = '/mandate/create';
export const CREATE_BULK_MANDATE = '/mandate/create/bulk';
export const VIEW_MANDATE_FROM_BULK_CREATION =
  '/mandate/create/bulk/:mandateId';
export const PREVIEW_MANDATE = '/mandate/preview';
export const MANDATE_SUCCESS = '/mandate/success';
export const MANDATE = '/mandate';
export const MANDATE_SINGLEVIEW = '/mandate/singleview/:mandateId';
export const MANDATE_BULKVIEW = '/mandate/bulkview/:mandateId';
export const MANDATE_BULKVIEW_DETAILS =
  '/mandate/bulkview/:bulkviewMandateId/:details';
export const SETTINGS = '/settings';
