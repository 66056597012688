import React from 'react';
import { Modal } from 'react-bootstrap';
import Text from '../text';
import SuccessIcon from '../../assets/svgs/successIcon.svg';
import Button from '../button';
import { closeModal } from '../../redux/features/modalSlice';
import { useAppDispatch } from '../../hooks/redux';

const TriggerDebitSuccessModal = () => {
  const dispatch = useAppDispatch();
  return (
    <Modal.Body className="d-flex flex-column align-items-center  py-5">
      <img src={SuccessIcon} alt="success" className="mx-auto" />

      <Text
        text="Success"
        size={4}
        bold
        color="var(--blue-600)"
        className="my-3"
      />
      <Text
        text={'Your  request have been successfully submited'}
        size={4}
        color="var(--Gray-600, #475467)"
        className="text-center mb-4"
      />

      <Button
        label="Close"
        onClick={() => {
          dispatch(closeModal());
        }}
        style={{ width: 254 }}
      />
    </Modal.Body>
  );
};

export default TriggerDebitSuccessModal;
