import React from 'react';

export interface IconName {
  /**
   * Icon name
   */
  name:
    | 'camera_icon'
    | 'pen_icon'
    | 'search_icon'
    | 'caret_down'
    | 'account'
    | 'open_eye'
    | 'closed_eye'
    | 'plus'
    | 'upward'
    | 'downward_arrow'
    | 'back_arrow'
    | 'forward_arrow'
    | 'filter'
    | 'calender'
    | 'bank'
    | 'arrow_left'
    | 'info'
    | 'copy'
    | 'upload';
  /**
   * Icon rotate degree
   */
  rotate?: number;
  width?: number;
  height?: number;
}

export interface IconProps extends IconName {
  /**
   * Icon width
   */
  width?: number;
  /**
   * Icon height
   */
  height?: number;
  /**
   * Color of an icon
   */
  color?: string;

  onClick?: (e?: unknown) => void;
}

const IconComponent: React.FC<IconProps> = ({
  name,
  rotate = 0,
  // width = 0,
  // height = 0,
  // color,
  ...props
}) => {
  switch (name) {
    case 'camera_icon':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 8C9.832 8 8 9.832 8 12C8 14.168 9.832 16 12 16C14.168 16 16 14.168 16 12C16 9.832 14.168 8 12 8ZM12 14C10.935 14 10 13.065 10 12C10 10.935 10.935 10 12 10C13.065 10 14 10.935 14 12C14 13.065 13.065 14 12 14Z"
            fill="#155EEF"
          />
          <path
            d="M20 5H17.414L14.707 2.293C14.6143 2.19996 14.5041 2.12617 14.3828 2.07589C14.2614 2.0256 14.1313 1.99981 14 2H10C9.86866 1.99981 9.73857 2.0256 9.61724 2.07589C9.4959 2.12617 9.38571 2.19996 9.293 2.293L6.586 5H4C2.897 5 2 5.897 2 7V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V7C22 5.897 21.103 5 20 5ZM4 18V7H7C7.266 7 7.52 6.895 7.707 6.707L10.414 4H13.586L16.293 6.707C16.3857 6.80004 16.4959 6.87383 16.6172 6.92412C16.7386 6.9744 16.8687 7.00019 17 7H20L20.002 18H4Z"
            fill="#155EEF"
          />
        </svg>
      );
    case 'pen_icon':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 19H19M14.5 2.49998C14.8978 2.10216 15.4374 1.87866 16 1.87866C16.2786 1.87866 16.5544 1.93353 16.8118 2.04014C17.0692 2.14674 17.303 2.303 17.5 2.49998C17.697 2.69697 17.8532 2.93082 17.9598 3.18819C18.0665 3.44556 18.1213 3.72141 18.1213 3.99998C18.1213 4.27856 18.0665 4.55441 17.9598 4.81178C17.8532 5.06915 17.697 5.303 17.5 5.49998L5 18L1 19L2 15L14.5 2.49998Z"
            stroke="#101828"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'search_icon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 2C15.9706 2 20 6.02944 20 11C20 13.1248 19.2637 15.0776 18.0323 16.6172L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3466 22.0676 20.7794 22.0953 20.3871 21.7903L20.2929 21.7071L16.6172 18.0323C15.0776 19.2637 13.1248 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2ZM11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4Z"
            fill="#353F50"
          />
        </svg>
      );
    case 'caret_down':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={25}
          height={25}
          viewBox="0 0 25 25"
          fill="none"
          transform={`rotate(${rotate})`}
          style={{ transition: '0.3s all' }}
        >
          <path
            d="M12.5007 14.6717L17.4507 9.72168L18.8647 11.1357L12.5007 17.4997L6.13672 11.1357L7.55072 9.72168L12.5007 14.6717Z"
            fill="#2C2A3B"
          />
        </svg>
      );
    case 'account':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={21}
          height={23}
          viewBox="0 0 21 23"
          fill="none"
        >
          <g clipPath="url(#clip0_7537_66087)">
            <path
              d="M20.1768 6.56096C20.6297 6.56096 20.9969 6.17627 20.9969 5.70171V4.7995C20.9969 2.42875 19.1558 0.5 16.8928 0.5C16.8928 0.5 4.03601 0.502019 4.00431 0.505929C2.92211 0.54554 1.88256 1.03205 1.13323 1.8546C0.365166 2.6977 -0.0352506 3.79427 0.00243719 4.94802C0.000878828 4.96989 5.86368e-05 17.3412 5.86368e-05 17.3412C5.86368e-05 20.1858 2.20908 22.5 4.92432 22.5H16.8929C19.1559 22.5 20.9969 20.5712 20.9969 18.2005V11.6767C20.9969 9.306 19.1559 7.37724 16.8929 7.37724H4.10097C2.78411 7.37724 1.7045 6.29898 1.64311 4.92246C1.6119 4.22252 1.85225 3.55437 2.31985 3.04105C2.7942 2.52035 3.4604 2.22176 4.14756 2.22176C4.1718 2.22176 16.8928 2.21849 16.8928 2.21849C18.2513 2.21849 19.3565 3.37633 19.3565 4.7995V5.70171C19.3566 6.17627 19.7238 6.56096 20.1768 6.56096ZM4.10097 9.09574H16.8928C18.2513 9.09574 19.3565 10.2536 19.3565 11.6767V18.2005C19.3565 19.6237 18.2513 20.7815 16.8928 20.7815H4.92432C3.11358 20.7815 1.64044 19.2382 1.64044 17.3412V8.23997C2.32645 8.77829 3.17883 9.09574 4.10097 9.09574ZM17.7162 14.9386C17.7162 15.5318 17.2572 16.0127 16.6909 16.0127C15.331 15.9561 15.3314 13.9209 16.6909 13.8646C17.2572 13.8646 17.7162 14.3454 17.7162 14.9386ZM17.7162 4.7995C17.7162 4.32494 17.349 3.94025 16.896 3.94025H4.10101C3.01278 3.98562 3.0136 5.61377 4.10101 5.65875H16.896C17.3489 5.65875 17.7162 5.27406 17.7162 4.7995Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_7537_66087">
              <rect
                width={21}
                height={22}
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case 'closed_eye':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={21}
          viewBox="0 0 20 21"
          fill="none"
          onClick={props.onClick}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.698 5.1374L8.84066 9.27935C9.14076 8.9894 9.55009 8.81089 10.0013 8.81089C10.9218 8.81089 11.668 9.55378 11.668 10.4702C11.668 10.9223 11.4864 11.3321 11.1918 11.6314L15.3046 15.744C15.63 16.0694 15.63 16.5971 15.3046 16.9225C14.9792 17.248 14.4515 17.248 14.1261 16.9225L13.3761 16.1734C12.3054 16.7934 11.1806 17.1074 10.0013 17.1074C7.0133 17.1074 4.37513 15.0918 2.07956 11.1881L1.90365 10.8841L1.66797 10.4702L1.90365 10.0563C2.60483 8.82493 3.33844 7.77215 4.1047 6.90168L3.51949 6.31591C3.19405 5.99048 3.19405 5.46284 3.51949 5.1374C3.84493 4.81196 4.37256 4.81196 4.698 5.1374ZM3.7894 10.1405L3.58907 10.4702L3.76244 10.7563C5.73062 13.9484 7.8131 15.4481 10.0013 15.4481C10.7287 15.4481 11.444 15.2827 12.1471 14.945L8.99829 11.7955C8.87235 11.7009 8.76033 11.5889 8.66582 11.4631L5.28255 8.07803C4.77783 8.6625 4.28007 9.349 3.7894 10.1405ZM10.0013 3.83301C12.9893 3.83301 15.6275 5.84853 17.923 9.75229L18.0989 10.0563L18.3346 10.4702L17.9273 11.1808C17.2867 12.2711 16.6193 13.2144 15.9251 14.0077L14.7484 12.8309C15.3125 12.1731 15.8676 11.3875 16.4136 10.4703C14.3944 7.08156 12.2539 5.4923 10.0013 5.4923C9.28773 5.4923 8.5854 5.65178 7.89461 5.97742L6.66328 4.74586C7.72299 4.13981 8.83552 3.83301 10.0013 3.83301Z"
            fill="black"
          />
        </svg>
      );

    case 'open_eye':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          onClick={props.onClick}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4C15.5878 4 18.7554 6.43241 21.5113 11.1435L21.7172 11.5011L22 12L21.5113 12.8565C18.7554 17.5676 15.5878 20 12 20C8.41215 20 5.24464 17.5676 2.48874 12.8565L2.28282 12.4989L2 12L2.28282 11.5011C5.08652 6.55556 8.32245 4 12 4ZM12 6C9.29692 6 6.72829 7.91554 4.30532 12C6.72829 16.0845 9.29692 18 12 18C14.6297 18 17.1289 16.1901 19.487 12.3447L19.6948 12.0001L19.4867 11.6553C17.1249 7.80768 14.6259 6 12 6ZM12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9Z"
            fill="#353F50"
          />
        </svg>
      );
    case 'plus':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11.9997 5.25L11.9998 12.1443M11.9998 12.1443L5.10547 12.1443M11.9998 12.1443L11.9998 19.0386M11.9998 12.1443L18.8941 12.1443"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'upward':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={32}
          height={32}
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M20 8V10H26.586L18 18.586L13.707 14.293C13.5195 14.1055 13.2652 14.0002 13 14.0002C12.7348 14.0002 12.4805 14.1055 12.293 14.293L2 24.586L3.414 26L13 16.414L17.293 20.707C17.4805 20.8945 17.7348 20.9998 18 20.9998C18.2652 20.9998 18.5195 20.8945 18.707 20.707L28 11.414V18H30V8H20Z"
            fill="#188E47"
          />
        </svg>
      );
    case 'filter':
      return (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.82125 3.72239C1.19097 3.01796 0.875826 2.66574 0.863939 2.3664C0.853612 2.10636 0.965359 1.85643 1.16603 1.69074C1.39704 1.5 1.86966 1.5 2.81491 1.5H15.184C16.1293 1.5 16.6019 1.5 16.8329 1.69074C17.0336 1.85643 17.1453 2.10636 17.135 2.3664C17.1231 2.66574 16.808 3.01796 16.1777 3.72239L11.4225 9.03703C11.2968 9.17745 11.234 9.24766 11.1892 9.32756C11.1495 9.39843 11.1203 9.47473 11.1027 9.55403C11.0828 9.64345 11.0828 9.73766 11.0828 9.92609V14.382C11.0828 14.5449 11.0828 14.6264 11.0565 14.6969C11.0333 14.7591 10.9955 14.8149 10.9463 14.8596C10.8907 14.9102 10.815 14.9404 10.6637 15.001L7.83039 16.1343C7.5241 16.2568 7.37096 16.3181 7.24802 16.2925C7.14052 16.2702 7.04617 16.2063 6.98551 16.1148C6.91613 16.0101 6.91613 15.8452 6.91613 15.5153V9.92609C6.91613 9.73766 6.91613 9.64345 6.89623 9.55403C6.87858 9.47473 6.84943 9.39843 6.8097 9.32756C6.76491 9.24766 6.70209 9.17745 6.57645 9.03703L1.82125 3.72239Z"
            stroke="#344054"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'calender':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
            stroke="#98A2B3"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'downward_arrow':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.9987 3.33301V12.6663M7.9987 12.6663L12.6654 7.99967M7.9987 12.6663L3.33203 7.99967"
            stroke="#475467"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'bank':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={20}
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M4.16667 7.50018V14.1668M7.91667 7.50018V14.1668M12.0833 7.50018V14.1668M15.8333 7.50018V14.1668M2.5 15.5002L2.5 16.1668C2.5 16.6336 2.5 16.8669 2.59083 17.0452C2.67072 17.202 2.79821 17.3295 2.95501 17.4094C3.13327 17.5002 3.36662 17.5002 3.83333 17.5002H16.1667C16.6334 17.5002 16.8667 17.5002 17.045 17.4094C17.2018 17.3295 17.3293 17.202 17.4092 17.0452C17.5 16.8669 17.5 16.6336 17.5 16.1668V15.5002C17.5 15.0335 17.5 14.8001 17.4092 14.6219C17.3293 14.4651 17.2018 14.3376 17.045 14.2577C16.8667 14.1668 16.6334 14.1668 16.1667 14.1668H3.83333C3.36662 14.1668 3.13327 14.1668 2.95501 14.2577C2.79821 14.3376 2.67072 14.4651 2.59083 14.6219C2.5 14.8001 2.5 15.0335 2.5 15.5002ZM9.71076 2.56446L3.54409 3.93483C3.17154 4.01761 2.98527 4.05901 2.84622 4.15918C2.72358 4.24754 2.62727 4.36759 2.56762 4.50648C2.5 4.66395 2.5 4.85477 2.5 5.23641L2.5 6.16685C2.5 6.63356 2.5 6.86691 2.59083 7.04517C2.67072 7.20197 2.79821 7.32946 2.95501 7.40935C3.13327 7.50018 3.36662 7.50018 3.83333 7.50018H16.1667C16.6334 7.50018 16.8667 7.50018 17.045 7.40935C17.2018 7.32946 17.3293 7.20197 17.4092 7.04517C17.5 6.86691 17.5 6.63356 17.5 6.16685V5.23641C17.5 4.85477 17.5 4.66395 17.4324 4.50649C17.3727 4.36759 17.2764 4.24754 17.1538 4.15918C17.0147 4.05901 16.8285 4.01762 16.4559 3.93483L10.2892 2.56446C10.1813 2.54047 10.1273 2.52848 10.0728 2.52369C10.0244 2.51945 9.97564 2.51945 9.9272 2.52369C9.87267 2.52848 9.8187 2.54047 9.71076 2.56446Z"
            stroke="black"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'back_arrow':
      return (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8346 6.99984H1.16797M1.16797 6.99984L7.0013 12.8332M1.16797 6.99984L7.0013 1.1665"
            stroke="#344054"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'arrow_left':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={21}
          height={20}
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            d="M17.1654 10H3.83203M3.83203 10L8.83203 15M3.83203 10L8.83203 5"
            stroke="#344054"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'forward_arrow':
      return (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16797 6.99984H12.8346M12.8346 6.99984L7.0013 1.1665M12.8346 6.99984L7.0013 12.8332"
            stroke="#344054"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'info':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={21}
          viewBox="0 0 20 21"
          fill="none"
        >
          <g clipPath="url(#clip0_7923_2163)">
            <path
              d="M10.0013 14.2585V10.9251M10.0013 7.5918H10.0096M18.3346 10.9251C18.3346 15.5275 14.6037 19.2585 10.0013 19.2585C5.39893 19.2585 1.66797 15.5275 1.66797 10.9251C1.66797 6.32276 5.39893 2.5918 10.0013 2.5918C14.6037 2.5918 18.3346 6.32276 18.3346 10.9251Z"
              stroke="#344054"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_7923_2163">
              <rect
                width={20}
                height={20}
                fill="white"
                transform="translate(0 0.925293)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case 'copy':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={21}
          height={21}
          viewBox="0 0 21 21"
          fill="none"
        >
          <path
            d="M12.168 7.09204H3.83464C2.91547 7.09204 2.16797 7.83954 2.16797 8.75871V17.092C2.16797 18.0112 2.91547 18.7587 3.83464 18.7587H12.168C13.0871 18.7587 13.8346 18.0112 13.8346 17.092V8.75871C13.8346 7.83954 13.0871 7.09204 12.168 7.09204Z"
            fill="#344054"
          />
          <path
            d="M17.168 2.09204H8.83464C8.39261 2.09204 7.96869 2.26764 7.65612 2.5802C7.34356 2.89276 7.16797 3.31668 7.16797 3.75871V5.42537H13.8346C14.2767 5.42537 14.7006 5.60097 15.0131 5.91353C15.3257 6.22609 15.5013 6.65001 15.5013 7.09204V13.7587H17.168C17.61 13.7587 18.0339 13.5831 18.3465 13.2706C18.659 12.958 18.8346 12.5341 18.8346 12.092V3.75871C18.8346 3.31668 18.659 2.89276 18.3465 2.5802C18.0339 2.26764 17.61 2.09204 17.168 2.09204Z"
            fill="#344054"
          />
        </svg>
      );
    case 'upload':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={21}
          height={20}
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            d="M7.16797 13.3333L10.5013 10M10.5013 10L13.8346 13.3333M10.5013 10V17.5M17.168 13.9524C18.1859 13.1117 18.8346 11.8399 18.8346 10.4167C18.8346 7.88536 16.7826 5.83333 14.2513 5.83333C14.0692 5.83333 13.8989 5.73833 13.8064 5.58145C12.7197 3.73736 10.7133 2.5 8.41797 2.5C4.96619 2.5 2.16797 5.29822 2.16797 8.75C2.16797 10.4718 2.86417 12.0309 3.99043 13.1613"
            stroke="#475467"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return null;
  }
};

export const Icon = React.memo(IconComponent);
