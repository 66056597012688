import React from 'react';
import styles from '../mandate.module.css';

interface IProps {
  leftBtn?: string;
  activeTab?: string;
  rightBtn?: string;
  changeTab: (tab: string) => void;
  classNames?: string;
}

const TwoTabsButton = ({
  leftBtn = 'Single',
  rightBtn = 'Bulk',
  changeTab,
  activeTab,
}: IProps) => {
  return (
    <div className={`${styles.generic_inputfield_style} d-flex text-sm`}>
      <button
        style={{
          background: activeTab === leftBtn ? '#1570EF' : '',
          color: activeTab === leftBtn ? 'white' : '',
        }}
        className=""
        onClick={() => changeTab(leftBtn)}
      >
        {leftBtn}
      </button>
      <button
        style={{
          background: activeTab === rightBtn ? '#1570EF' : '',
          color: activeTab === rightBtn ? 'white' : '',
        }}
        className=""
        onClick={() => changeTab(rightBtn)}
      >
        {rightBtn}
      </button>
    </div>
  );
};

export default TwoTabsButton;
