import React, { CSSProperties, FC, ReactNode } from 'react';
import styles from './detailsCard.module.css';
import { combineClassName } from '../../utils/utilsMethods';

const DetailsCard: FC<{
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}> = ({ children, style, className }) => {
  return (
    <div
      className={combineClassName(styles.details_card_wrapper, className)}
      style={style ? { ...style } : {}}
    >
      {children}
    </div>
  );
};

export default DetailsCard;
