import React, { useEffect, useState } from 'react';
import Text from '../../../../components/text';
import styles from '../../mandate.module.css';
import { Icon } from '../../../../components/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { MANDATE } from '../../../../routes/navigations';
import { BULK_MANDATE } from '../../../../utils/constants';
import Card from '../../../../components/card';
import {
  combineClassName,
  daysItem,
  formatMandateDate,
  formattedCurrency,
  getDateFilter,
  // getDateFilter,
} from '../../../../utils/utilsMethods';
import BulkMandateColumn from '../../components/BulkMandateColumn';
import TextInput from '../../../../components/textInput';
import BulkMandateTable from './BulkMandateTable';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux';
import {
  selectMandateState,
  setLoadingMandate,
} from '../../../../redux/features/mandateSlice';
import CustomDropdown, {
  IItemProps,
} from '../../../../components/customDropdown';
import useRouteQuery from '../../../../hooks/useRouteQuery';
import api from '../../../../network/api';
import { Content, ISelectableMandateList } from '../../../../interface/mandate';

const BulkViewScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize] = useState(10);
  const { searchParams, setURLQuery } = useRouteQuery();
  const dateLabel = searchParams.get('dateLabel');
  const query = searchParams.get('query');
  const { mandateId } = useParams();
  const { batchList, loading, searchableString } =
    useAppSelector(selectMandateState);
  const [mandateList, setMandateList] = useState<ISelectableMandateList[]>([]);
  const [totalPageAndElement, setTotalPageElement] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const defaultFilterDate = daysItem.find((day) => day?.label === dateLabel);

  const [filterMandate, setFilterMandate] = useState<IItemProps>(
    defaultFilterDate || daysItem[5]
  );

  function handleMandateFilter(value: IItemProps) {
    setFilterMandate(value);
  }

  const fetchMandateList = async (filterMandate: IItemProps) => {
    const filterResult = getDateFilter(filterMandate);
    const startDate = filterResult.fromDate;
    const endDate = filterResult.toDate;

    try {
      dispatch(setLoadingMandate(true));
      const res = await api.fetchAllMandate(
        currentPage,
        currentSize,
        startDate!,
        endDate,
        false,
        query?.length ? query : mandateId!
      );

      setMandateList(
        (res?.data?.content as Content[]).map((item) => ({
          ...item,
          selected: false,
        }))
      );

      setTotalPageElement({
        totalElements: res?.data?.totalElements,
        totalPages: res?.data?.totalPages,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoadingMandate(false));
    }
  };

  useEffect(() => {
    fetchMandateList(filterMandate);
  }, [query, filterMandate, currentPage]);

  const { batchReference, dateCreated, totalMandateAmount, totalMandates } =
    batchList.find((mandate) => mandate.batchReference === mandateId) ?? {};

  const updateUrl = (dateLabel: string) => {
    const newState = { dateLabel };
    setURLQuery(newState);
  };

  const debounce = (delay = 1000) => {
    let timeout: string | number | NodeJS.Timeout | null | undefined = null;
    return (text: string) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        handleSearchMandate(text);
      }, delay);
    };
  };

  const handleSearchMandate = async function (searchTerm: string) {
    setURLQuery({ query: searchTerm });
  };

  const debounceFunction = debounce();

  function updateSearchField(term: string) {
    debounceFunction(term);
  }

  const gotoNextPage = () => {
    if (
      totalPageAndElement?.totalPages &&
      currentPage < totalPageAndElement?.totalPages
    ) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const gotoPreviousPage = () => {
    if (totalPageAndElement?.totalPages && currentPage > 0)
      setCurrentPage((prev) => prev - 1);
  };

  const gotoPage = (page: number) => {
    if (totalPageAndElement?.totalPages) setCurrentPage(page);
  };

  return (
    <main className="container">
      <header className="pt-3 pb-2">
        <button
          onClick={() => {
            navigate(MANDATE, { state: { view: BULK_MANDATE } });
          }}
          className={`${styles.mandate_btn} d-flex gap-2 px-3 align-items-center`}
        >
          <Icon name="back_arrow" /> <span className="">Back</span>
        </button>
        <div className="d-flex pt-4 pb-4 justify-content-between align-items-center flex-wrap gap-2 gap-lg-0">
          <div>
            <Text
              text={'Batch details - ' + '#' + mandateId}
              size={6}
              bold
              color="var(--vfd-main-body-text)"
            />
            <Text
              text={'Overview of all your mandates'}
              size={1}
              color={'var(--vfd-main-body-text)'}
            />
          </div>
        </div>
      </header>
      <div className="row">
        <Card
          className={combineClassName(
            styles.mandate_card,
            styles.batch_mandate_card,
            `gap-md-5 gap-2`
          )}
        >
          <BulkMandateColumn text={batchReference!} title="Batch Reference" />
          <BulkMandateColumn
            text={formatMandateDate(dateCreated!, 'dd/MM/yyyy')}
            title="Date Created"
          />
          <BulkMandateColumn
            text={formattedCurrency(Number(totalMandateAmount))}
            title="Total value of mandate"
          />
          <BulkMandateColumn
            text={String(totalMandates)}
            title="Total Beneficiaries"
          />
        </Card>

        <Card
          className={combineClassName(
            styles.mandate_table,
            `flex-column shadow px-0`
          )}
        >
          <div className="px-4 pt-4 pb-4 d-flex w-100 justify-content-between flex-wrap">
            <TextInput
              label="Email"
              placeholder="Search using reference"
              id="global-search_90192"
              name="search_90192"
              type="search"
              value={searchableString}
              onChange={(_, value) => updateSearchField(value)}
              className="mb-2 mb-sm-0"
              // errorText={validationResult.email}
              // valid={!validationResult.email}
              // isRequired
            />

            <CustomDropdown
              showIcon={false}
              items={daysItem}
              selectedOption={filterMandate}
              showSelectedLabel
              handleSelect={(item) => {
                updateUrl(item.label);
                handleMandateFilter(item);
              }}
              defaultLabel="Filter by:"
            />
          </div>

          <BulkMandateTable
            totalPageAndElement={totalPageAndElement}
            itemList={mandateList}
            loading={loading}
            gotoNextPage={gotoNextPage}
            gotoPreviousPage={gotoPreviousPage}
            gotoPage={gotoPage}
            currentPage={currentPage}
          />
        </Card>
      </div>
    </main>
  );
};

export default BulkViewScreen;
