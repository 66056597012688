import React, { FormEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from '../../Screens/CreateMandate/mandate.module.css';
import Card from '../card';
import Text from '../text';
import { fontFamily } from '../../utils/fonts';
import { combineClassName, frequencyInWords } from '../../utils/utilsMethods';
import CustomSelect from '../customSelect';
import TextInput from '../textInput';
import Radio from '../radio';
import {
  ICreateBulkMandateList,
  IGetBanks,
  frequencyType,
} from '../../interface/mandate';
import { createMandateValidation } from '../../utils/validations';
import { useValidation } from '../../hooks/useValidation';
import {
  selectGlobalState,
  setBankList,
} from '../../redux/features/globalSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useToast from '../../hooks/useToast';
import api from '../../network/api';
import DatePickerComponent from '../datePicker';
import { IError } from '../../interface/network';
import moment from 'moment';
import DetailsCard from '../detailsCard';
import LabelAndValue from '../LabelAndValue';
import Button from '../button';
import { closeModal } from '../../redux/features/modalSlice';
import {
  selectMandateState,
  setCreateBulkMandate,
} from '../../redux/features/mandateSlice';

export const frequencyObj = {
  1: 'weekly',
  4: 'monthly',
  12: 'annually',
};

export function getFrequency(val: string) {
  switch (val) {
    case 'weekly':
      return 1;
    case 'monthly':
      return 4;
    case 'annually':
      return 12;
    default:
      return 4;
  }
}

const EditMandateModal = () => {
  const toast = useToast();
  const globalState = useAppSelector(selectGlobalState);
  const { data, addData, validationResult, setValidationResult } =
    useValidation(createMandateValidation);

  const {
    bulkMandate: { batchList, batchReference, totalElements, totalPages },
  } = useAppSelector(selectMandateState);

  const { modalProps } = useAppSelector((state) => state.modal);

  const [banks, setBanks] = useState<IGetBanks['data']['bank']>([]);
  const [bankValues, setBankValues] = useState<string[]>([]);
  const [accountValidated, setAccountValidated] = useState(false);
  // const [validationInfo, setValidationInfo] =
  //   useState<IValidateAccountResponse['data']>();

  const dispatch = useAppDispatch();

  const {
    amount,
    payerAccountNumber,
    payerBank,
    frequency,
    payerPhone,
    payerEmailAddress,
    startDate,
    endDate,
    reference,
  } = modalProps.details as ICreateBulkMandateList;

  const selectedBankName = banks.find(
    (bank) => bank.code == String(payerBank) || bank.name == String(payerBank)
  );

  useEffect(() => {
    addData('payerBank', String(payerBank));
    addData('startDate', new Date(startDate));
    addData('endDate', new Date(endDate));
    addData('amount', String(amount));
    addData('payerAccountNumber', String(payerAccountNumber));
    addData('payerPhone', String(payerPhone));
    addData('payerEmailAddress', String(payerEmailAddress));
    addData('reference', reference);
  }, []);

  useEffect(() => {
    if (!globalState?.bankList?.length) {
      getBankList();
    } else {
      setBanks(globalState.bankList);
      setBankValues([...globalState.bankList.map((bank) => bank.name)]);
    }

    addData('receiverBank', '999999');
  }, []);

  useEffect(() => {
    if (globalState?.receiverAccountNo) {
      addData('receiverAccountNumber', globalState.receiverAccountNo);
    }
  }, [globalState]);

  async function getBankList() {
    try {
      const response = await api.getBanks();
      setBanks(response.data.bank);
      setBankValues([...response.data.bank.map((bank) => bank.name)]);
      dispatch(setBankList(response.data.bank));
    } catch (error) {
      const err = error as IError;
      toast.error(err.message || '');
    }
  }

  function onInputChange(name: string, value: string) {
    if (name === 'amount') {
      const newValue = value.replace(/,/g, '');
      addData(name, Number(newValue ?? 0).toLocaleString());
    } else {
      addData(name, value);
    }
  }

  const handleSelect = (bank: string): void => {
    const payerBank = banks.find((bankData) => bankData.name === bank);
    if (payerBank) addData('payerBank', payerBank?.code);
  };

  function getFrequency(val: string) {
    switch (val) {
      case 'weekly':
        return 1;
      case 'monthly':
        return 4;
      case 'annually':
        return 12;
      default:
        return 4;
    }
  }

  async function validateAccountNumber() {
    try {
      const response = await api.validateAccount({
        accountNo: data.payerAccountNumber as string,
        bank: isNaN(Number(data?.payerBank))
          ? (selectedBankName?.code as string)
          : (data.payerBank as string),
        transfer_type: selectedBankName?.code === '999999' ? 'intra' : 'inter',
      });
      addData('payerBvn', response.data.bvn);
      setAccountValidated(true);
      // setValidationInfo(response.data);
    } catch (error) {
      validationResult.payerAccountNumber = 'xxxxxxx';
      setValidationResult((prev) => {
        return {
          ...prev,
          payerAccountNumber: err.message || 'Account validation failed',
        };
      });
      const err = error as IError;
      toast.error(err.message || '');
    }
  }

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      if (
        data.payerBank &&
        data.payerAccountNumber &&
        (data.payerAccountNumber as string).length === 10
      ) {
        validateAccountNumber();
      }
    }, 800);

    return () => clearTimeout(debounceFn);
  }, [data.payerAccountNumber, data.payerBank, payerAccountNumber]);

  function handleRadioChange(value: string) {
    addData('frequency', getFrequency(value)?.toString());
  }

  function handleDateChange(name: string, date: Date) {
    const value = moment(date).format('DD MMMM YYYY');
    addData(name, value);
  }

  function onClickContinue(e: FormEvent) {
    e.preventDefault();
    try {
      if (!accountValidated) {
        toast.error("Unable to validate payer's account number");
        return;
      } else {
        const newData = [...batchList];
        const updatedData = newData?.map((item) => {
          if (item?.reference === data?.reference) {
            return {
              ...item,
              ...data,
              amount: Number((data?.amount as string).replace(/,/g, '')),
            };
          } else {
            return item;
          }
        });

        dispatch(
          setCreateBulkMandate({
            batchReference,
            batchList: updatedData,
            totalElements,
            totalPages,
          })
        );

        closeEditModal();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const closeEditModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal.Body className="pt-0">
      <Card className={`${styles.form_wrapper} border-0 p-2 mt-0`}>
        <div className="d-flex align-items-center" style={{ height: 40 }}>
          <Text
            size={3}
            bold
            text="Payer account information"
            color="var(--SPN-900)"
            style={{ fontFamily: fontFamily.extraBold }}
          />
        </div>

        <form onSubmit={onClickContinue}>
          <div className={combineClassName('row', styles.form_section_one)}>
            <div className="col-md-6">
              <CustomSelect
                label="Payers Bank Name"
                searchable={true}
                labelDescription="Select payer's bank"
                placeholder="Select payer's bank"
                values={bankValues}
                onChange={handleSelect}
                selectedValue={selectedBankName?.name as string}
                isRequired
                // disabled={true}
                // hideLabel={false}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                label="Payers Account Number"
                placeholder="Enter payer's account number"
                id="payerAccountNumber"
                name="payerAccountNumber"
                max={10}
                type="text"
                onChange={onInputChange}
                errorText={validationResult.payerAccountNumber}
                valid={!validationResult.payerAccountNumber}
                defaultValue={String(data?.payerAccountNumber)}
                isRequired
              />
            </div>

            <div className="col-md-6">
              <TextInput
                label="Payer's Phone"
                placeholder="Enter payer's phone number"
                id="payerPhone"
                name="payerPhone"
                type="text"
                onChange={onInputChange}
                errorText={validationResult.payerPhone}
                valid={!validationResult.payerPhone}
                defaultValue={String(data?.payerPhone) || ''}
                isRequired
              />
            </div>
            <div className="col-md-6">
              <TextInput
                label="Payer's Email Address"
                placeholder="Enter payer's email address"
                id="payerEmailAddress"
                name="payerEmailAddress"
                type="email"
                onChange={onInputChange}
                errorText={validationResult.payerEmailAddress}
                valid={!validationResult.payerEmailAddress}
                defaultValue={data?.payerEmailAddress as string}
                isRequired
              />
            </div>
          </div>
          <div className={combineClassName('row', styles.form_section_two)}>
            <div className="col-md-6">
              <TextInput
                label="Amount"
                placeholder="Enter amount"
                id="amount"
                name="amount"
                type="text"
                defaultValue={String(data?.amount as string) ?? ''}
                onChange={onInputChange}
                errorText={validationResult.amount}
                valid={!validationResult.amount}
                isRequired
              />
            </div>

            <div className="col-md-6 ">
              <Radio
                label="Select Frequency"
                items={[
                  { label: 'Weekly', value: 'weekly' },
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'Annually', value: 'annually' },
                ]}
                onChange={handleRadioChange}
                errorText={validationResult.frequency}
                valid={!validationResult.frequency}
                isRequired
                defaultValue={
                  (frequencyObj[
                    frequency as keyof typeof frequencyObj
                  ] as string) || frequencyInWords(frequency as frequencyType)
                }
              />
            </div>

            <div className="col-md-6">
              <div className="w-100">
                <DatePickerComponent
                  label="Start Date"
                  onChange={(date) => handleDateChange('startDate', date)}
                  isRequired
                  errorText={validationResult.startDate}
                  valid={!validationResult.startDate}
                  defaultValue={new Date(startDate) as Date}
                  name="startDate"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100">
                <DatePickerComponent
                  label="End Date"
                  onChange={(date) => handleDateChange('endDate', date)}
                  isRequired
                  errorText={validationResult?.endDate}
                  valid={!validationResult?.endDate}
                  defaultValue={new Date(endDate) as Date}
                  name="endDate"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center" style={{ height: 40 }}>
              <Text
                size={3}
                bold
                text="Receiver account information"
                color="var(--SPN-900)"
                style={{ fontFamily: fontFamily.extraBold }}
              />
            </div>
            <div className="row">
              <div className="col-12">
                <DetailsCard>
                  <div className="row w-100">
                    <div className="col-md-4">
                      <LabelAndValue
                        label="Receiver's Bank"
                        value="VFD MFB"
                        valueIconName="bank"
                      />
                    </div>
                    <div className="col-md-4">
                      <LabelAndValue
                        label="Receiver’s Account Number"
                        value={globalState.receiverAccountNo || ''}
                      />
                    </div>
                    <div className="col-md-4">
                      <LabelAndValue
                        label="Account Name"
                        value={globalState.receiverName || ''}
                      />
                    </div>
                  </div>
                </DetailsCard>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end gap-3 align-items-center mt-3">
            <div style={{ width: 151 }}>
              <Button
                style={{ borderColor: '#D0D5DD' }}
                type="outline"
                label="Cancel"
                onClick={() => closeEditModal}
              />
            </div>
            <div style={{ width: 300 }}>
              <Button type="primary" label="Continue" />
            </div>
          </div>
        </form>
      </Card>
    </Modal.Body>
  );
};

export default EditMandateModal;
