import React, { CSSProperties, FC } from 'react';
import Text from '../text';
import { Icon, IconName } from '../icons';

interface IProps {
  label: string;
  value: string;
  valueIconName?: IconName['name'];
  valueStyle?: CSSProperties;
}
const LabelAndValue: FC<IProps> = ({
  label,
  value,
  valueIconName,
  valueStyle,
}) => {
  return (
    <div className="d-flex flex-column gap-2">
      <Text
        style={{ fontWeight: '500' }}
        text={label}
        color="var(--Gray-600, #475467)"
      />
      <div className="d-flex flex-row gap-2">
        {valueIconName && <Icon name={valueIconName} />}
        <Text
          bold
          text={value}
          color={valueStyle?.color ? valueStyle.color : 'var(--Gray-700)'}
          style={valueStyle ? { ...valueStyle } : {}}
        />
      </div>
    </div>
  );
};

export default LabelAndValue;
