import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import LabelAndValue from '../LabelAndValue';
import Card from '../card';
import {
  combineClassName,
  formatMandateDate,
  formattedCurrency,
} from '../../utils/utilsMethods';
import bulkViewStyle from '../../Screens/Mandate/BulkView/Bulkview_Details_Screen/bulkview_details.module.css';
import { closeModal, openModal } from '../../redux/features/modalSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import Text from '../text';
import { MODAL_TYPES } from '../../utils/constants';
import { IMandateHistoryContent, modalTypes } from '../../interface/mandate';
import { TRANSACTION_STATUS } from '../../Screens/Mandate/Single/SingleView/TransactionHistoryTable';

const TransactionReceipt = () => {
  const dispatch = useAppDispatch();
  const handleSubmit = () => {};
  const {
    modalProps: { transactionType, transactionDetails },
  } = useAppSelector((state) => state.modal);

  const {
    amount,
    mandateReference,
    transactionId,
    sessionId,
    time,
    walletName,
    fromAccountNo,
  } = transactionDetails as IMandateHistoryContent;

  const success = 'success';

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body className="d-flex flex-column gap-2">
        <Card className={combineClassName(bulkViewStyle.bulk_view_card, 'p-3')}>
          <div className="d-flex flex-wrap justify-content-between gap-3 gap-md-0">
            <div className="col-md-4">
              <LabelAndValue
                label="Payment Reference"
                value={mandateReference}
                valueStyle={{ paddingRight: '1rem' }}
              />
            </div>
            <div className="col-md-4">
              <LabelAndValue
                label="Source Account"
                value={fromAccountNo}
                valueStyle={{ paddingRight: '1rem' }}
              />
            </div>
            <div className="col-md-4">
              <LabelAndValue label="Beneficiary Name" value={walletName} />
            </div>
          </div>

          <div className="d-flex flex-wrap justify-content-between gap-3 gap-md-0">
            <div className="col-md-4">
              <LabelAndValue
                label="Beneficiary Account"
                value="VFD MFB"
                valueStyle={{ paddingRight: '1rem' }}
              />
            </div>
            <div className="col-md-4">
              <LabelAndValue
                label="Amount"
                value={formattedCurrency(Number(amount))}
                valueStyle={{ paddingRight: '1rem' }}
              />
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-wrap flex-column gap-2">
                <Text
                  style={{ fontWeight: '500' }}
                  text={'Status'}
                  color="var(--Gray-600, #475467)"
                />
                <div className="d-flex flex-row gap-2">
                  {transactionType == 'success' && TRANSACTION_STATUS['00']}
                  {transactionType == 'failed' && TRANSACTION_STATUS['09']}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap justify-content-between gap-3 gap-md-0">
            <div className="col-md-4">
              <LabelAndValue
                label="Session ID"
                value={sessionId}
                valueStyle={{ paddingRight: '1rem' }}
              />
            </div>
            <div className="col-md-4">
              <LabelAndValue
                label="Transaction ID"
                value={transactionId}
                valueStyle={{ paddingRight: '1rem' }}
              />
            </div>
            <div className="col-md-4">
              <LabelAndValue
                label="Transaction Date"
                value={formatMandateDate(time)}
                valueStyle={{ paddingRight: '1rem' }}
              />
            </div>
          </div>
        </Card>
      </Modal.Body>

      <Modal.Footer className="pb-4 pt-0 border-0">
        {transactionType == success ? (
          <Button
            onClick={() => {
              dispatch(closeModal());
            }}
            variant="primary"
            className={`btn d-flex gap-2  text-center justify-content-center py-2 px-5 align-items-center`}
          >
            Close
          </Button>
        ) : (
          <Button
            onClick={() =>
              dispatch(
                openModal({
                  modalType:
                    MODAL_TYPES.TRIGGER_DEBIT_SUCCESS_MODAL as modalTypes,
                  isOpen: true,
                  modalProps: {},
                })
              )
            }
            variant="danger"
            className={`btn d-flex gap-2  text-center justify-content-center py-2 px-5 align-items-center`}
          >
            Raise dispute
          </Button>
        )}
      </Modal.Footer>
    </form>
  );
};

export default TransactionReceipt;
