import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { getItem, removeItem, setItem } from '../../utils/storage';
import { storageKey } from '../../utils/constants';

// Define a type for the slice state
interface AuthState {
  authenticated: boolean;
  token?: string | null
}
const token = getItem(storageKey.AUTH_TOKEN)


// Define the initial state using that type
const initialState: AuthState = {
  authenticated: token ? true : false,
  token: null
};

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthState['token']>) => {
      state.authenticated = true;
      if (action.payload) setItem(storageKey.AUTH_TOKEN, action.payload)
      state.token = action.payload
    },
    logout: (state) => {
      removeItem(storageKey.AUTH_TOKEN)
      state.authenticated = false;
      state.token = null
    },
  },
});

export const { setAuthState, logout } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;
