import React from 'react';
import { Oval } from 'react-loader-spinner';
import Text from '../text';

const Loader = ({ text }: { text?: string }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className="py-4 d-flex flex-column w-100 justify-content-center align-items-center
      "
    >
      <Oval
        visible={true}
        height="80"
        secondaryColor="#F2F4F7"
        width="80"
        color="#1570EF"
        ariaLabel="oval-loading"
      />

      <Text
        text={text ? text : ''}
        size={2}
        className="d-inline ps-2 mt-2"
        color={'var(--vfd-main-body-text)'}
      />
    </div>
  );
};

export default Loader;
