import secureLocalStorage from "react-secure-storage";


export function setItem(key: string, value: string | number | boolean | object) {
    secureLocalStorage.setItem(key, value)
}

export function getItem(key: string) {
    return secureLocalStorage.getItem(key)
}

export function removeItem(key: string) {
    secureLocalStorage.removeItem(key)
}

export function clear() {
    secureLocalStorage.clear()
}