import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import './styles.scss';

export interface ButtonProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  label: ReactNode;
  type?: 'primary' | 'outline' | 'link' | 'danger';
  onClick?: () => void;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
  loading?: boolean;
}
const Button: React.FC<ButtonProps> = ({
  label,
  type = 'primary',
  onClick,
  disabled = false,
  size = 'large',
  loading = false,
  ...props
}) => {
  const renderButton = (): JSX.Element => {
    switch (size) {
      case 'large':
        return (
          <button
            onClick={onClick}
            disabled={disabled || loading}
            className={`btn btn-${type}`}
            style={props.style}
          >
            <>{loading ? 'Please wait...' :label}</>
          </button>
        );
      case 'small':
        return (
          <button
            onClick={onClick}
            disabled={disabled || loading}
            className={`btn btn-${type} small`}
            style={props.style}
          >
            <>{loading ? 'Please wait...' :label}</>
          </button>
        );
      case 'medium':
        return (
          <button
            onClick={onClick}
            disabled={disabled || loading}
            className={`btn btn-${type} medium`}
            style={props.style}
          >
            <>{loading ? 'Please wait...' : label}</>
          </button>
        );
    }
  };
  return (
    <div className={`Button ${props.className ? props.className : ''}`}>
      {renderButton()}
    </div>
  );
};

export default Button;
