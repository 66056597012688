import React, { useEffect, useState } from 'react';
import {
  addEllipsisToText,
  calcDuration,
  combineClassName,
  formatMandateDate,
  formattedCurrency,
  frequencyInWords,
} from '../../../utils/utilsMethods';
import BulkMandateColumn from './BulkMandateColumn';
import bulkViewStyle from '../BulkView/Bulkview_Details_Screen/bulkview_details.module.css';
import styles from '../mandate.module.css';
import Card from '../../../components/card';
import Text from '../../../components/text';
import Button from '../../../components/button';
import { useLocation, useParams } from 'react-router-dom';
import api from '../../../network/api';
import {
  IMandateDetailsContent,
  frequencyType,
  modalTypes,
} from '../../../interface/mandate';
// import DetailsCard from '../../../components/detailsCard';
import LabelAndValue from '../../../components/LabelAndValue';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import {
  selectGlobalState,
  setBankList,
} from '../../../redux/features/globalSlice';
import Loader from '../../../components/Loader/Loader';
import { openModal } from '../../../redux/features/modalSlice';
import { MODAL_TYPES } from '../../../utils/constants';
import { MANDATE_STATUS } from './SingleMandateTable';
import { IError } from '../../../interface/network';
import useToast from '../../../hooks/useToast';

interface IPayerDetails {
  accountName: string;
  accountNumber: string;
  bankName: string;
  phoneNumber: string;
}

export const PayerDetailsRow = ({
  accountName,
  accountNumber,
  bankName,
  phoneNumber,
}: IPayerDetails) => {
  return (
    <div className={combineClassName(bulkViewStyle.four_col_grid)}>
      <BulkMandateColumn text={accountName} title={'Account Name'} />
      <BulkMandateColumn text={accountNumber} title="Account Number" />
      <BulkMandateColumn text={bankName} title="Bank" />
      <BulkMandateColumn text={phoneNumber} title="Phone number" />
    </div>
  );
};

const BulkMandateDetailsCard = () => {
  const { details: mandateDetailsId, mandateId } = useParams();
  const toast = useToast();
  const [details, setDetails] = useState<IMandateDetailsContent['data']>();
  const [loading, setLoading] = useState(false);
  const globalState = useAppSelector(selectGlobalState);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const idToUse =
    pathname.split('/')[2] == 'singleview' ? mandateId : mandateDetailsId;

  const fetchMandateDetails = async () => {
    try {
      setLoading(true);
      const response = await api.fetchAllMandateDetails(idToUse as string);
      setDetails(response.data);
    } catch (error) {
      const err = error as IError;
      toast.error(err.message ?? 'An error occured');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMandateDetails();
  }, [idToUse]);

  async function getBankList() {
    try {
      const response = await api.getBanks();
      dispatch(setBankList(response.data.bank));
    } catch (error) {
      const err = error as IError;
      toast.error(err.message || '');
    }
  }

  useEffect(() => {
    getBankList();
  }, []);

  const {
    amount,
    frequency,
    startDate,
    endDate,
    dateCreated,
    payerAccountName,
    payerAccountNo,
    payerPhone,
    mandateStatus,
    payerBank: payerBankId,
  } = details ?? {};

  const payerBank = globalState?.bankList?.find(
    (bank) => bank.code === payerBankId
  )?.name as string;

  const handleTriggerDebitModal = (modalType: keyof typeof MODAL_TYPES) => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: modalType,
        modalProps: {
          ...details,
          title: 'Trigger Debit',
          amount,
          payerBank,
          payerAccountNo,
        },
      })
    );
  };

  return (
    <Card
      className={combineClassName(
        styles.mandate_table,
        `flex-column shadow p-4`
      )}
    >
      <Text
        text={'Mandate details - #' + addEllipsisToText(idToUse!, 12)}
        size={4}
        bold
        className="pb-4"
        color="var(--vfd-main-body-text)"
      />

      {!loading ? (
        <Card
          className={combineClassName(
            bulkViewStyle.bulk_view_card,
            bulkViewStyle.five_col_grid
          )}
        >
          <BulkMandateColumn
            text={
              addEllipsisToText(details?.mandateReference as string, 10) || '--'
            }
            title="Mandate Reference"
          />
          <BulkMandateColumn
            text={formattedCurrency(Number(amount))}
            title="Mandate Amount"
          />
          <BulkMandateColumn
            badge={
              !mandateStatus || !mandateStatus.length
                ? MANDATE_STATUS['NULL']
                : MANDATE_STATUS[mandateStatus as keyof typeof MANDATE_STATUS]
            }
            title="Mandate Status"
          />
          <BulkMandateColumn
            textColor="red"
            text="--"
            title="Outstanding Balance"
          />
          <BulkMandateColumn
            text={calcDuration(startDate!, endDate!)}
            title="Duration"
          />
          <BulkMandateColumn
            text={frequencyInWords(Number(frequency) as frequencyType)}
            title="Frequency"
          />
          <BulkMandateColumn
            text={formatMandateDate(
              dateCreated || String(new Date()),
              'MMM dd, yyyy'
            )}
            title="Date Created"
          />
          <BulkMandateColumn
            text={formatMandateDate(
              startDate || String(new Date()),
              'MMM dd, yyyy'
            )}
            title="Start Date"
          />
          <BulkMandateColumn
            text={formatMandateDate(
              endDate || String(new Date()),
              'MMM dd, yyyy'
            )}
            title="End Date"
          />
        </Card>
      ) : (
        <Loader text="Loading Mandate Details" />
      )}

      <div className="d-flex justify-content-end mt-3 mb-5">
        <Button
          label="Trigger debit"
          onClick={() =>
            handleTriggerDebitModal(
              MODAL_TYPES.TRIGGER_DEBIT_MODAL as modalTypes
            )
          }
          disabled={loading}
          className="px-3"
          style={{ paddingInline: '2rem' }}
        />
      </div>

      <div className="d-flex flex-md-nowrap flex-wrap gap-md-5 mb-3">
        <Text
          text={'Payer Details'}
          size={4}
          bold
          className="pb-4"
          color="var(--vfd-main-body-text)"
          style={{ minWidth: '150px' }}
        />

        <Card
          className={combineClassName(
            bulkViewStyle.bulk_view_card,
            bulkViewStyle.payer_details_card
          )}
        >
          <PayerDetailsRow
            accountName={payerAccountName || '--'}
            accountNumber={payerAccountNo || '--'}
            bankName={payerBank || '--'}
            phoneNumber={payerPhone || '--'}
          />
        </Card>
      </div>

      <div className="d-flex gap-md-5 justify-content-between flex-wrap flex-md-nowrap mt-5">
        <Text
          text={'Receiver Details'}
          size={4}
          bold
          className="pb-4"
          color="var(--vfd-main-body-text)"
          style={{ minWidth: '150px' }}
        />

        <Card
          className={combineClassName(
            bulkViewStyle.bulk_view_card,
            bulkViewStyle.payer_details_card
          )}
          style={{ width: '-webkit-fill-available' }}
        >
          <div className="row w-100">
            <div className="col-md-4">
              <LabelAndValue
                label="Receiver's Bank"
                value="VFD MFB"
                valueIconName="bank"
              />
            </div>
            <div className="col-md-4">
              <LabelAndValue
                label="Receiver’s Account Number"
                value={globalState.receiverAccountNo || ''}
              />
            </div>
            <div className="col-md-4">
              <LabelAndValue
                label="Account Name"
                value={globalState.receiverName || ''}
              />
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
};

export default BulkMandateDetailsCard;
