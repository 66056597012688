import React from 'react';
import styles from '../mandate.module.css';
import { Icon } from '../../../components/icons';
import { ThreeDots } from 'react-loader-spinner';
import Pagination from 'react-bootstrap/Pagination';

interface IProps {
  totalPages?: number;
  currentPage?: number;
  gotoNextPage?: () => void;
  gotoPreviousPage?: () => void;
  gotoPage?: (page: number) => void;
  loading?: boolean;
}

const MandatePagination = ({
  totalPages = 0,
  gotoNextPage,
  gotoPreviousPage,
  gotoPage,
  loading,
  currentPage = 0,
}: IProps) => {
  return (
    <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-3">
      <button
        onClick={() => gotoPreviousPage && gotoPreviousPage()}
        className={`${styles.mandate_btn} d-flex gap-2 px-3 align-items-center`}
        disabled={currentPage + 1 === 1}
      >
        <Icon name="back_arrow" /> <span className="">Previous</span>
      </button>

      <div className="w-25 d-flex d-xl-none mx-auto justify-content-center gap-2 align-items-center">
        <span className="">{currentPage + 1}</span>{' '}
        {totalPages ? <span className="">/ {totalPages}</span> : null}
      </div>

      <div className="w-25 d-none d-xl-flex mx-auto justify-content-center gap-2 align-items-center">
        {!loading ? (
          <>
            {Array.from({
              length: totalPages > 20 ? 20 : totalPages || 0,
            })?.map((_, index: number) => {
              const activePage = currentPage + 1 === index + 1;
              return (
                <button
                  className="btn"
                  key={index}
                  style={{
                    background: activePage ? '#F9FAFB' : '',
                  }}
                  onClick={() => gotoPage && gotoPage(index)}
                >
                  {index + 1}
                </button>
              );
            })}
            {currentPage + 1 >= 20 ? (
              <button
                onClick={() => gotoNextPage && gotoNextPage()}
                disabled={currentPage + 1 === totalPages}
                className="btn"
                style={{ background: '#F9FAFB' }}
              >
                <Pagination.Next
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </button>
            ) : null}
          </>
        ) : (
          <ThreeDots
            visible={true}
            height="50"
            width="50"
            color="#1570EF"
            radius="9"
            ariaLabel="three-dots-loading"
          />
        )}
      </div>

      <button
        onClick={() => gotoNextPage && gotoNextPage()}
        disabled={currentPage + 1 === totalPages}
        className={`${styles.mandate_btn} d-flex gap-2 px-3 align-items-center`}
      >
        <span className="">Next</span> <Icon name="forward_arrow" />
      </button>
    </div>
  );
};

export default MandatePagination;
