import React, { useEffect, useState } from 'react';
import Text from '../../../components/text';
import styles from '../home.module.css';
import { fontFamily } from '../../../utils/fonts';
import Button from '../../../components/button';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import api from '../../../network/api';
import { IMandateHistoryContent } from '../../../interface/mandate';
import EmptyState from '../../../components/EmptyState/EmptyState';
import { formatMandateDate } from '../../../utils/utilsMethods';
import RecentCollection, { RecentCollectionHeader } from './recentCollection';

const RecentTransaction = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [transactionList, setTransactionList] = useState<
    IMandateHistoryContent[]
  >([]);

  const fetchMandateList = async () => {
    // const _ = getDateFilter(date);
    const startDate = `2000-01-01 00:00:00`;
    const endDate = `${formatMandateDate(
      new Date().toUTCString(),
      'yyyy-MM-dd hh:mm:ss'
    )}`;

    try {
      setLoading(true);
      const res = await api.fetchRecentTransaction(0, 10, startDate, endDate);

      const mandates = res?.data?.content;

      setTransactionList(mandates);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMandateList();
  }, []);
  return (
    <>
      <div className={`${styles.wrapperTitle} overflow-x-auto`}>
        <Text
          text="Recent transaction status"
          size={3}
          style={{ fontFamily: fontFamily.bold }}
          color="#000000"
          className="align-self-start"
        />

        {!loading && transactionList?.length ? (
          <Button
            style={{
              background: 'linear-gradient(45deg, #0017E4 0%, #3793FF 100%)',
              height: 32,
              paddingTop: 4,
            }}
            className="align-self-start"
            label="View all"
            onClick={() => navigate('/mandate')}
          />
        ) : null}
      </div>
      {!loading && transactionList.length ? (
        <>
          <RecentCollectionHeader />
          {transactionList?.map((transaction, i) => (
            <RecentCollection key={i.toString()} transaction={transaction} />
          ))}
        </>
      ) : null}

      {!loading && !transactionList?.length && <EmptyState />}

      {loading && <Loader text="Loading Recent Transaction..." />}
    </>
  );
};

export default RecentTransaction;
