import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { MODAL_TYPES } from '../../utils/constants';
import TriggerDebitModal from '../Modals/TriggerDebitModal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { closeModal } from '../../redux/features/modalSlice';
import Text from '../text';
import TriggerDebitSuccessModal from '../Modals/TriggerDebitSuccessModal';
import TransactionReceipt from '../Modals/TransactionReceipt';
import EditMandateModal from '../Modals/EditMandateModal';
import SubmitBatchModal from '../Modals/SubmitBatchModal';

const modalToShow = {
  [MODAL_TYPES.TRIGGER_DEBIT_MODAL]: <TriggerDebitModal />,
  [MODAL_TYPES.TRIGGER_DEBIT_SUCCESS_MODAL]: <TriggerDebitSuccessModal />,
  [MODAL_TYPES.TRANSACTION_RECEIPT]: <TransactionReceipt />,
  [MODAL_TYPES.EDIT_TRANSACTION_MODAL]: <EditMandateModal />,
  [MODAL_TYPES.SUBMIT_BATCH_MODAL]: <SubmitBatchModal />,
};

function Modals() {
  const { modalType, isOpen, modalProps } = useAppSelector(
    (state) => state.modal
  );
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };
  const {
    title,
    subtitle,
    showHeader = true,
    size,
    titleWithImage = '',
  } = modalProps;
  return (
    <Modal
      show={isOpen}
      centered
      onHide={handleClose}
      style={{ border: 'none' }}
      size={size}
    >
      {showHeader ? (
        <Modal.Header
          bsPrefix="border-0 d-flex justify-content-between align-items-center p-3 pt-3"
          closeButton
        >
          <Modal.Title as="h6" style={{ fontWeight: 'bold' }}>
            <>
              {titleWithImage && (
                <img
                  src={titleWithImage as string}
                  width={52}
                  height={52}
                  alt="Save Icon"
                />
              )}
              {title as string}
              {(subtitle as string)?.length && (
                <Text
                  text={
                    (subtitle as string) ||
                    'Enter another account information and save'
                  }
                  size={2}
                  color="var(--Gray-600, #475467)"
                />
              )}
            </>
          </Modal.Title>
        </Modal.Header>
      ) : null}

      {modalToShow[modalType]}
    </Modal>
  );
}

export default Modals;
