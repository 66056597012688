import React, { useEffect, useState } from 'react';
import styles from './home.module.css';
import Text from '../../components/text';
import Card from '../../components/card';
import { Icon } from '../../components/icons';
import { fontFamily } from '../../utils/fonts';
import Button from '../../components/button';
import CardBg from '../../assets/images/dashboard-card.svg';
import CollectionTab from './components/collectionTab';
import ReactApexChart from 'react-apexcharts';
// import RecentCollection from './components/recentCollection';
import PageTitle from '../../components/pageTitle';
import { useNavigate } from 'react-router';
import { CREATE_MANDATE } from '../../routes/navigations';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  selectGlobalState,
  setShowBalance,
} from '../../redux/features/globalSlice';
import {
  daysItem,
  formatCash,
  getDateFilter,
  handleCamelcasing,
} from '../../utils/utilsMethods';
import { IError } from '../../interface/network';
import useToast from '../../hooks/useToast';
import api from '../../network/api';
import { IItemProps } from '../../components/customDropdown';
import LoadingState from '../../components/loadingState';
import {
  setCreateMandateData,
  // selectMandateState,
  setMandateStat,
} from '../../redux/features/mandateSlice';
import { ITransactionAnalysisResponseValue } from '../../interface/mandate';
// import useFetchMandate from '../../hooks/useFetchMandate';
// import Loader from '../../components/Loader/Loader';
import RecentTransaction from './components/RecentTransaction';
import EmptyState from '../../components/EmptyState/EmptyState';

const Home = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const globalState = useAppSelector(selectGlobalState);
  const [mandateAnalyticsLoading, setMandateAnalyticsLoading] = useState(false);
  const [transactionAnalyticsLoading, setTransactionAnalyticsLoading] =
    useState(false);
  const [analyticsFilterValue, setAnalyticsFilterValue] = useState<IItemProps>(
    daysItem[5]
  );
  const [totalCollection, setTotalCollection] =
    useState<ITransactionAnalysisResponseValue | null>(null);
  const [donutData, setDonutData] = useState({
    active: '0',
    pending: '0',
    failed: '0',
  });
  const [seriesDonut, setSeriesDonut] = useState<number[]>([]); //success, pending, failed

  const [seriesBar, setSeriesBar] = useState<Array<{ data: number[] }>>([
    {
      data: [0],
    },
  ]);

  const [optionBar, setOptionBar] = useState({
    legend: { show: false },

    chart: {
      toolbar: {
        show: false,
      },
      column: true,
    },

    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
        columnWidth: '55%',
        distributed: true,
      },
    },
    colors: [
      '#268DB8',
      '#6A7889',
      '#39F03A',
      '#90970A',
      '#3DFCC0',
      '#EBD812',
      '#C8EA50',
      '#7191CA',
      '#7180CF',
      '#1570EF',
    ],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [] as string[],
      labels: {
        rotate: 270,
      },
    },
  });

  const backgroundColors = [
    'var(--vfd-green-vfd-green-500)',
    'var(--blue-600)',
    '#FFC31A',
  ];

  const optionDonut = {
    legend: { show: false },
    // colors: ['red', 'blue'],
    chartOptions: {
      // labels: ['Apple', 'Mango'],
    },
    colors: backgroundColors,
    tooltip: {
      enabled: false, // Hide the tooltips
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return formatCash(val.toString());
      },
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
      },
    },

    responsive: [
      {
        breakpoint: 767,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const [seriesLine, setSeriesLine] = useState<
    Array<{ name: string; data: number[] }>
  >([
    {
      name: 'Total collection value',
      data: [],
    },
    {
      name: 'Total failed collection',
      data: [],
    },
  ]);

  const [optionLine, setOptionLine] = useState({
    colors: ['var(--Supportive-Roshi-100)', 'var(--error)'],
    legend: { show: false },
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight' as ApexStroke['curve'],
      width: 2,
      // color: 'red',
    },
    // title: {
    //   text: 'Product Trends by Month',
    //   align: 'left',
    // },
    grid: {
      row: {
        colors: ['transparent', 'transparent'],
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: [] as string[],
      labels: {
        style: {
          colors: 'var(--Light-Trunks)',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: 'var(--Light-Trunks)',
        },
      },
    },
  });

  useEffect(() => {
    getMandateAnalytics();
    getTransactionAnalytics();
  }, [analyticsFilterValue]);

  function toggleView() {
    dispatch(setShowBalance());
  }

  async function getMandateAnalytics() {
    setMandateAnalyticsLoading(true);
    try {
      const filterResult = getDateFilter(analyticsFilterValue);
      const response = await api.getMandateAnalytics({
        startDate: filterResult.fromDate,
        endDate: filterResult.toDate,
      });
      setSeriesDonut([
        parseInt(response.data.piechart.success),
        parseInt(response.data.piechart.pending),
        parseInt(response.data.piechart.failed),
      ]);
      setDonutData({
        active: response.data.piechart.success,
        pending: response.data.piechart.pending,
        failed: response.data.piechart.failed,
      });
      if (analyticsFilterValue.label === '12 months') {
        dispatch(
          setMandateStat({
            success: response.data.piechart.success,
            pending: response.data.piechart.pending,
            failed: response.data.piechart.failed,
          })
        );
      }

      setSeriesBar([
        {
          data: [
            ...Object.values(response.data.barchart).map((dat) =>
              parseInt(dat)
            ),
          ],
        },
      ]);
      setOptionBar({
        ...optionBar,
        xaxis: {
          ...optionBar.xaxis,
          categories: [...Object.keys(response.data.barchart)],
        },
      });
    } catch (error: unknown) {
      const err = error as IError;
      toast.error(err.message || '');
    }
    setMandateAnalyticsLoading(false);
  }

  async function getTransactionAnalytics() {
    setTransactionAnalyticsLoading(true);
    try {
      const filterResult = getDateFilter(analyticsFilterValue);
      const response = await api.getMandateTransactionAnalytics({
        startDate: filterResult.fromDate,
        endDate: filterResult.toDate,
      });
      if (response.statusCode) delete response?.statusCode;
      const values = Object.values(response);
      const collections = values.find(
        (value) =>
          (value as ITransactionAnalysisResponseValue).monthAndYear === 'Total'
      );
      setTotalCollection(collections as ITransactionAnalysisResponseValue);
      values.pop();
      const categories: string[] = [];
      const success: string[] = [];
      const failed: string[] = [];
      values.forEach((value) => {
        const newValue = value as ITransactionAnalysisResponseValue;
        categories.push(newValue.monthAndYear);
        success.push(newValue.successful);
        failed.push(newValue.failed);
      });
      setSeriesLine([
        {
          name: 'Total collection value',
          data: [...success.map(Number)],
        },
        {
          name: 'Total failed collection',
          data: [...failed.map(Number)],
        },
      ]);
      setOptionLine({
        ...optionLine,
        xaxis: { ...optionLine.xaxis, categories },
      });
    } catch (error: unknown) {
      const err = error as IError;
      toast.error(err.message || '');
    }
    setTransactionAnalyticsLoading(false);
  }

  function handleAnalyticsFilter(value: IItemProps) {
    setAnalyticsFilterValue(value);
  }

  function getSuccessRate() {
    const values = Object.values(donutData);
    const addedValues = values.reduce((acc, val) => {
      return (acc += Number(val));
    }, 0);
    return (Number(donutData.active) * 100) / addedValues;
  }

  function getPercentage(input: number) {
    if (!totalCollection?.failed || !totalCollection?.successful) return 0;
    const total =
      Number(totalCollection?.failed ?? 0) +
      Number(totalCollection?.successful ?? 0);
    const result = (input * 100) / total;
    return isNaN(result) ? 0 : Math.round(result);
  }

  function RenderBarLabel() {
    return (
      <div className="d-flex flex-column">
        {optionBar.xaxis.categories.map((item, i) => (
          <div
            key={i.toString + item}
            style={{
              height: 30,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              borderBottom: 'solid 1px rgba(0,0,0,0.1)',
              gap: 15,
            }}
          >
            <Text text={handleCamelcasing(item)} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                flex: 1,
              }}
            >
              <Text text={seriesBar[0].data[i]?.toString()} />
            </div>
          </div>
        ))}
        <div
          style={{
            height: 30,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderTop: 'solid 1px rgba(0,0,0,0.6)',
            borderBottom: 'solid 1px rgba(0,0,0,0.1)',
            gap: 15,
          }}
        >
          <Text text="Grand Total" />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              flex: 1,
            }}
          >
            <Text
              text={seriesBar[0].data
                .reduce((acc, data) => (acc += data))
                .toString()}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <PageTitle
        hideSearch={true}
        handleSelectFilter={handleAnalyticsFilter}
        selectedFilter={analyticsFilterValue}
      />

      <div className="row">
        <div className="col-lg-5">
          <Card className={styles.dashboard_card}>
            <div>
              <Text text="Billing account balance" size={2} color="#000000" />
              <div className="d-flex gap-1 flex-row align-items-center">
                <Icon name="account" />
                <Text
                  text={
                    globalState?.showBalance
                      ? `₦ ${formatCash(globalState.accountBalance || '')}`
                      : '***********'
                  }
                  size={6}
                  style={{
                    fontWeight: '800',
                    fontFamily: fontFamily.extraBold,
                  }}
                  color="#000000"
                />
                <span style={{ cursor: 'pointer' }}>
                  <Icon
                    name={globalState?.showBalance ? 'closed_eye' : 'open_eye'}
                    onClick={toggleView}
                  />
                </span>
              </div>
            </div>

            <div className="d-flex gap-3 align-items-center flex-row"></div>
          </Card>
        </div>
        <div className="col-lg-7">
          <Card
            className={styles.dashboard_card}
            style={{ position: 'relative' }}
          >
            <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
              <img src={CardBg} alt="card" />
            </div>
            <div style={{ zIndex: 2 }}>
              <Text
                text="Setup a Direct Debit Mandate"
                style={{
                  fontWeight: '800',
                  fontFamily: fontFamily.extraBold,
                }}
                size={4}
                color="#000000"
              />
              <Text
                text="Manage all your collections with ease"
                size={1}
                color="#000000"
              />
            </div>
            <div className={styles.mandate_button_wrapper}>
              <Button
                label="Create New Mandate"
                onClick={() => {
                  dispatch(setCreateMandateData(null));
                  navigate(CREATE_MANDATE);
                }}
              />
            </div>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-7">
          <Card className={styles.graph_card}>
            <Text text={'Total Collections'} size={3} bold color="#000000" />
            {!transactionAnalyticsLoading &&
            optionLine?.xaxis?.categories.length ? (
              <div className="d-flex justify-content-center align-items-center flex-row gap-2">
                <CollectionTab
                  label="Total collection value"
                  value={'₦' + formatCash(totalCollection?.successful) ?? 0}
                  percentage={
                    totalCollection?.successful
                      ? getPercentage(Number(totalCollection?.successful ?? 0))
                      : 0
                  }
                />
                <CollectionTab
                  label="Total Failed collection"
                  value={'₦' + formatCash(totalCollection?.failed) ?? 0}
                  percentage={
                    totalCollection?.failed
                      ? -getPercentage(Number(totalCollection?.failed ?? 0))
                      : 0
                  }
                />
              </div>
            ) : null}

            {!transactionAnalyticsLoading ? (
              <ReactApexChart
                series={seriesLine}
                options={optionLine}
                type="line"
                height={250}
              />
            ) : null}
            {!transactionAnalyticsLoading &&
            !optionLine?.xaxis?.categories.length ? (
              <EmptyState />
            ) : null}
            {transactionAnalyticsLoading && <LoadingState />}
          </Card>
        </div>
        <div className="col-lg-5">
          <Card className={styles.recentWrapper}>
            <RecentTransaction />
          </Card>
        </div>

        <div className="col-lg-4">
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              padding: '40px 30px 40px 30px',
              boxShadow: 'none',
              height: 540,
              marginBottom: 20,
            }}
          >
            <div className="d-flex flex-row gap-2 align-items-center ps-2">
              <Text
                text="Success rate:"
                color="var(--VFD-Main-Lables)"
                size={4}
                className="align-self-start"
                bold
              />

              {!mandateAnalyticsLoading && seriesDonut.length ? (
                <>
                  <Text
                    text={`${formatCash(getSuccessRate())}%`}
                    color="var(--vfd-green-vfd-green-500)"
                    size={5}
                    bold
                  />
                  {getSuccessRate() > 0 && <Icon name="upward" />}
                </>
              ) : null}
            </div>
            {!mandateAnalyticsLoading && seriesDonut.length ? (
              <div className="justify-self-center d-flex justify-content-center">
                <ReactApexChart
                  series={seriesDonut}
                  options={optionDonut}
                  type="donut"
                  width="100%"
                  height={300}
                />
              </div>
            ) : null}
            {!mandateAnalyticsLoading && !seriesDonut.length && <EmptyState />}
            {mandateAnalyticsLoading && <LoadingState />}
            {!mandateAnalyticsLoading && seriesDonut.length ? (
              <>
                <div>
                  <Text
                    size={2}
                    style={{ fontWeight: 600 }}
                    color="var(--VFD-Main-Lables)"
                    text="Total No of Mandates"
                  />
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="d-flex gap-2 align-items-center">
                    <div
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: 2,
                        background: 'var(--vfd-green-vfd-green-500, #188E47)',
                      }}
                    />
                    <Text
                      text="Active"
                      size={1}
                      color="var(--VFD-Main-Placeholder)"
                    />
                  </div>

                  <div className="d-flex gap-2 align-items-center">
                    <div
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: 2,
                        background: 'var(--vfd-yellow-vfd-yellow-500)',
                      }}
                    />
                    <Text
                      text="Failed"
                      size={1}
                      color="var(--VFD-Main-Placeholder)"
                    />
                  </div>

                  <div className="d-flex gap-2 align-items-center">
                    <div
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: 2,
                        background: 'var(--blue-600)',
                      }}
                    />
                    <Text
                      text="Pending"
                      size={1}
                      color="var(--VFD-Main-Placeholder)"
                    />
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="border-end pe-5">
                    <Text size={1} color="#353F50" text="Active" />
                    <Text
                      size={1}
                      color="var(--vfd-green-vfd-green-500"
                      text={donutData.active}
                    />
                  </div>
                  <div className="border-end pe-5">
                    <Text size={1} color="#353F50" text="Failed" />
                    <Text
                      size={1}
                      color="var(--vfd-yellow-vfd-yellow-500)"
                      text={donutData.failed}
                    />
                  </div>
                  <div>
                    <Text size={1} color="#353F50" text="Pending" />
                    <Text
                      size={1}
                      color="var(--blue-600)"
                      text={donutData.pending}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </Card>
        </div>

        <div className="col-lg-8">
          <Card className={styles.graph_card_two}>
            <Text
              text="Mandate Overview"
              size={4}
              bold
              color="var=(--VFD-Main-Lables)"
            />
            {!mandateAnalyticsLoading && optionBar?.xaxis?.categories.length ? (
              <div className="d-flex gap-lg-5 flex-lg-row flex-column">
                <div className={`${styles.graph_part}`}>
                  <ReactApexChart
                    series={seriesBar}
                    options={optionBar}
                    type="bar"
                    height={500}
                  />
                </div>

                {RenderBarLabel()}
              </div>
            ) : null}

            {!mandateAnalyticsLoading &&
            !optionBar?.xaxis?.categories.length ? (
              <EmptyState />
            ) : null}
            {mandateAnalyticsLoading && <LoadingState />}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Home;
