import React, { useState } from 'react';
import Text from '../../components/text';
import Card from '../../components/card';
import Tab from '../../components/tab';
import Profile from './components/Profile';

const index = () => {
  const views = {
    '0': <Profile />,
  };

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [navs] = useState(['Profile']);

  return (
    <main className="container pb-5 min-vh-100">
      <header className="pt-4 pb-2">
        <div className="d-flex pb-4 justify-content-between align-items-center flex-wrap gap-2 gap-lg-0">
          <div>
            <Text
              text={'Settings'}
              size={6}
              bold
              color="var(--vfd-main-body-text)"
            />
            <Text
              text={'Overview of all your account'}
              size={2}
              color={'var(--vfd-main-body-text)'}
            />
          </div>
        </div>
      </header>

      <Card className="py-3 px-4 border-0">
        <Tab navs={navs} setSelectedIndex={setSelectedIndex} />

        {views[selectedIndex.toString() as keyof typeof views]}
      </Card>
    </main>
  );
};

export default index;
