import React, { FC, ReactNode } from 'react';
import styles from './text.module.css';
import { combineClassName } from '../../utils/utilsMethods';
// import { fontFamily } from '../../utils/fonts';

interface IProps {
  className?: string;

  /**
   * 8 is the largest, 1 is the smallest
   */
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  /**
   * Text to be displayed
   */
  text: string;

  /**
   * color of the text
   */
  color?: string;

  style?: React.CSSProperties;

  onClick?: () => void;

  bold?: boolean;

  children?: ReactNode;
}
const Text: FC<IProps> = ({
  className,
  size = 2,
  text,
  color = 'var(--text-dark)',
  style,
  onClick,
  bold,
  children,
}) => {
  function getSizeValue() {
    switch (size) {
      case 1:
        return '0.75rem';
      case 2:
        return '0.875rem';
      case 3:
        return '1rem';
      case 4:
        return '1.125rem';
      case 5:
        return '1.25rem';
      case 6:
        return '1.5rem';
      case 7:
        return '1.625rem';
      case 8:
        return '1.75rem';
      default:
        return '0.875rem';
    }
  }
  return (
    <>
      {!children ? (
        <p
          style={{
            fontSize: getSizeValue(),
            color: color,
            fontWeight: bold ? '700' : '400',
            // ...(bold ? { fontFamily: fontFamily.extraBold } : {}),
            overflow: 'auto',
            ...style,
          }}
          onClick={onClick ? onClick : () => null}
          className={combineClassName(styles.text, className ?? '')}
        >
          {text}
        </p>
      ) : (
        { children }
      )}
    </>
  );
};

export default Text;
