/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import styles from './container.module.css';
import {
  base64PreferredPrefix,
  combineClassName,
  updateFavIcon,
} from '../utils/utilsMethods';
import { useAppSelector } from '../hooks/redux';
import { selectAuthState } from '../redux/features/authSlice';
import { HOME } from '../routes/navigations';
import { selectGlobalState } from '../redux/features/globalSlice';

const PublicContainer = () => {
  const authState = useAppSelector(selectAuthState);
  const logo = useAppSelector(selectGlobalState).companyLogo;
  const [showMenu, setShowMenu] = React.useState(false);

  useEffect(() => {
    if (logo) {
      // const img = new Image();
      updateFavIcon(logo);
    }
  }, [logo]);

  if (authState.authenticated) {
    return <Navigate to={HOME} replace />;
  }

  function toggleMenu() {
    setShowMenu((prev) => !prev);
  }

  return (
    <div>
      <header className={styles.header}>
        <nav className={styles.navbar}>
          <div style={{ display: 'flex', alignItems: 'center', height: 40 }}>
            {logo && (
              <img
                src={base64PreferredPrefix + logo}
                className={styles.nav_logo}
                alt="Logo"
              />
            )}
          </div>

          {/* <div
            onClick={toggleMenu}
            className={combineClassName(
              styles.hamburger,
              showMenu ? styles.active : ''
            )}
          >
            <span className={styles.bar} />
            <span className={styles.bar} />
            <span className={styles.bar} />
          </div> */}
        </nav>
      </header>
      <div className="row">
        <div className={combineClassName('col-md-5', styles.left_side)}></div>
        <div className="col-md-7" style={{ backgroundColor: '#F6F8FA' }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PublicContainer;
