import React, { ReactNode, useState } from 'react';
import styles from './dropdown.module.css';
import { combineClassName } from '../../utils/utilsMethods';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Icon, IconProps } from '../icons';
import useClickAway from '../../hooks/useClickAway';

export interface IDropdownItem {
  label: string;
  value: string;
  icon?: string;
}

interface IDropdownProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  label?: string | ReactNode;
  items: Array<IDropdownItem>;
  onSelectItem: (item: IDropdownItem) => void;
  labelColor?: string;
}

export default function Dropdown({ ...props }: IDropdownProps) {
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow((prev) => !prev);
  };

  const { ref } = useClickAway<HTMLDivElement>(() => setShow(false));

  const selectItem = (item: IDropdownItem) => {
    props.onSelectItem(item);
  };

  return (
    <div
      style={props.style ? props.style : {}}
      ref={ref}
      className={combineClassName(
        `${
          props.label ? styles.dropdown_wrapper : styles.dropdown_wrapper_two
        }`,
        'd-flex justify-content-between align-items-center'
      )}
      onClick={toggle}
    >
      {props.label && (
        <div style={props.labelColor ? { color: props.labelColor } : {}}>
          {props.label}
        </div>
      )}
      {props.label && (
        <Icon
          name="caret_down"
          color={props.labelColor}
          rotate={show ? 180 : 0}
        />
      )}

      {show && (
        <div className={styles.dropdown_items}>
          {props.items.length > 0 &&
            props.items.map((item) => (
              <div
                key={item.value}
                className={styles.dropdown_item}
                onClick={() => selectItem(item)}
              >
                {item.icon && (
                  <Icon name={item.icon as IconProps['name']} color="#000000" />
                )}

                <div>{item.label}</div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
