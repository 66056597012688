import { useEffect, useState } from 'react';
import api from '../network/api';
import {
  selectMandateState,
  setBatchList,
  setLoadingMandate,
  setMandateList,
  setTotalPageAndElement,
} from '../redux/features/mandateSlice';
import { useAppDispatch, useAppSelector } from './redux';
import { Content, IBatchMandate } from '../interface/mandate';
import { IItemProps } from '../components/customDropdown';
import { daysItem, getDateFilter } from '../utils/utilsMethods';
import useRouteQuery from './useRouteQuery';
import { BULK_MANDATE, SINGLE_MANDATE } from '../utils/constants';
import { useLocation } from 'react-router-dom';

const useFetchMandate = (dateLabel?: string) => {
  const dispatch = useAppDispatch();
  const { totalPages, mandates, recentTransactions } =
    useAppSelector(selectMandateState);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize] = useState(10);
  const defaultFilterDate = daysItem.find((day) => day.label === dateLabel);
  const { searchParams } = useRouteQuery();
  const isBulkQuery = searchParams.get('queryType');
  const searchMandate = searchParams.get('search');
  const { state } = useLocation();

  const [filterMandate, setFilterMandate] = useState<IItemProps>(
    defaultFilterDate || daysItem[5]
  );

  function handleMandateFilter(value: IItemProps) {
    setFilterMandate(value);
  }

  const isBulk = () => {
    if (isBulkQuery === BULK_MANDATE) {
      return true;
    } else if (isBulkQuery === SINGLE_MANDATE) {
      return false;
    } else if (state?.view) {
      return true;
    } else if (!isBulkQuery) {
      return false;
    } else {
      return true;
    }
  };

  const fetchMandateList = async (date: IItemProps, isBulk: boolean) => {
    const filterResult = getDateFilter(date);
    const startDate = filterResult.fromDate;
    const endDate = filterResult.toDate;

    try {
      dispatch(setLoadingMandate(true));
      const res = await api.fetchAllMandate(
        currentPage,
        currentSize,
        startDate!,
        endDate,
        isBulk,
        searchMandate || ''
      );

      if (isBulk) {
        dispatch(setBatchList(res?.data?.content as IBatchMandate[]));
      } else {
        dispatch(
          setMandateList(
            (res?.data?.content as Content[]).map((item) => ({
              ...item,
              selected: false,
            }))
          )
        );
      }

      dispatch(
        setTotalPageAndElement({
          totalElements: res?.data?.totalElements,
          totalPages: res?.data?.totalPages,
        })
      );

      // if (searchMandate?.length) {
      //   setCurrentPage(0);
      // }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoadingMandate(false));
    }
  };

  // const memoizedMandateList = useCallback(
  //   (filterMandate: IItemProps) => fetchMandateList(filterMandate),
  //   [isBulk, currentPage, filterMandate]
  // );

  useEffect(() => {
    fetchMandateList(filterMandate, isBulk());
  }, [currentPage, isBulk(), searchMandate, filterMandate]);

  const gotoNextPage = () => {
    if (totalPages && currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const gotoPreviousPage = () => {
    if (totalPages && currentPage > 0) setCurrentPage((prev) => prev - 1);
  };

  const gotoPage = (page: number) => {
    if (totalPages) setCurrentPage(page);
  };

  return {
    gotoNextPage,
    gotoPreviousPage,
    gotoPage,
    currentPage,
    mandateList: mandates,
    handleMandateFilter,
    filterMandate,
    recentTransactions,
  };
};

export default useFetchMandate;
