import React, { FC, useEffect, useState } from 'react';
import Text from '../text';
import styles from './radio.module.css';
import { combineClassName } from '../../utils/utilsMethods';

interface IProps {
  label: string;
  items: Array<{ label: string; value: string }>;
  onChange: (val: string) => void;
  isRequired?: boolean;
  errorText?: string;
  valid?: boolean;
  defaultValue?: string;
}

const Radio: FC<IProps> = ({
  label,
  items,
  onChange,
  valid = true,
  errorText = '',
  isRequired = false,
  defaultValue,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>();
  function handleChange(val: string, i: number) {
    if (onChange) onChange(val);
    setSelectedIndex(i);
  }

  useEffect(() => {
    if (defaultValue) {
      const foundItemIndex = items.findIndex(
        (item) => item.value.toLowerCase() == defaultValue.toLowerCase()
      );
      if (foundItemIndex >= 0) {
        setSelectedIndex(foundItemIndex);
      }
    }
  }, [defaultValue]);

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <div className="d-flex flex-row">
        <Text size={2} text={label} color="var(--black)" />
        {isRequired && <span style={{ color: 'var(--error)' }}>*</span>}
      </div>
      <div className="d-flex flex-row gap-4">
        {items.map((item, i) => (
          <div
            className="form-check"
            key={i + item.value}
            onClick={() => handleChange(item.value, i)}
          >
            <input
              className="form-check-input"
              type="radio"
              id={item.label}
              readOnly
              checked={selectedIndex == i}
            />

            <label
              className={combineClassName(
                styles.form_check_label,
                'form-check-label'
              )}
              htmlFor="flexRadioDefault1"
            >
              {item.label}
            </label>
          </div>
        ))}
      </div>
      <div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
        {errorText}
      </div>
    </div>
  );
};

export default Radio;
