import { configureStore } from '@reduxjs/toolkit';
import authReducer, { authSlice } from './features/authSlice';
import globalReducer, { globalSlice } from './features/globalSlice';
import mandateReducer, { mandateSlice } from './features/mandateSlice';
import modalReducer, { modalSlice } from './features/modalSlice';

const store = configureStore({
  reducer: {
    [authSlice.name]: authReducer,
    [globalSlice.name]: globalReducer,
    [mandateSlice.name]: mandateReducer,
    [modalSlice.name]: modalReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
