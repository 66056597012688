import React, { useState } from 'react';
import Text from '../../../components/text';
import Button from '../../../components/button';
import Card from '../../../components/card';
import cardStyles from '../../Mandate/mandate.module.css';
import {
  // addEllipsisToText,
  combineClassName,
  formattedCurrency,
} from '../../../utils/utilsMethods';
import BulkMandateColumn from '../../Mandate/components/BulkMandateColumn';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { selectMandateState } from '../../../redux/features/mandateSlice';
import TextInput from '../../../components/textInput';
import BulkCreationTable from './BulkCreationTable';
import { useNavigate } from 'react-router-dom';
import { openModal } from '../../../redux/features/modalSlice';
import { MODAL_TYPES } from '../../../utils/constants';
import saveIcon from '../../../assets/svgs/saveIcon.svg';

const CreateBulkMandateDetails = () => {
  const navigate = useNavigate();
  function handleGoBack() {
    navigate(-1);
  }

  const [searchedString, setSearchedString] = useState('');

  const dispatch = useAppDispatch();

  const {
    bulkMandate: { batchReference, batchList, currentPage },
    searchableString,
  } = useAppSelector(selectMandateState);

  const totalMandateAmount = batchList?.reduce((acc, cur) => {
    return (acc = acc + Number(cur?.amount));
  }, 0);

  const totalMandateBeneficiaries = batchList.length || '--';

  const ITEMS_PER_PAGE = 10;

  // const list = batchList?.slice(
  //   currentPage * ITEMS_PER_PAGE,
  //   (currentPage + 1) * ITEMS_PER_PAGE
  // );

  const list = batchList
    .filter(
      (mandate) =>
        mandate?.payerEmailAddress
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchedString?.toLowerCase())
    )
    .slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE);

  const handleSubmitAllMandate = (submitSelectedMandate: boolean = false) => {
    dispatch(
      openModal({
        modalType: MODAL_TYPES.SUBMIT_BATCH_MODAL,
        isOpen: true,
        modalProps: {
          size: 'sm',
          titleWithImage: saveIcon,
          submitSelectedMandate,
        },
      })
    );
  };
  return (
    <main className="container">
      <header className="d-flex pt-4 pb-4 justify-content-between align-items-center flex-wrap gap-2 gap-lg-0">
        <div>
          <Text
            text={'Batch Details'}
            size={6}
            bold
            color="var(--vfd-main-body-text)"
          />
          <Text
            text={'Preview your details before you submit'}
            size={3}
            color={'var(--vfd-main-body-text)'}
          />
        </div>
        <div className="d-flex gap-2 align-items-center">
          <Button
            style={{
              borderRadius: 6,
              border: '1px solid var(--Gray-300, #D0D5DD)',
              background: 'var(--Base-White, #FFF)',
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              fontWeight: '700',
              minWidth: '120px',
            }}
            onClick={() => handleGoBack()}
            type={'outline'}
            label={<span>Cancel</span>}
            className="medium"
          />
          <Button
            label="Submit all Batch"
            onClick={() => handleSubmitAllMandate()}
            type="primary"
          />
        </div>
      </header>

      <Card
        className={combineClassName(
          cardStyles.mandate_card,
          cardStyles.batch_mandate_card,
          `gap-md-5 gap-2`
        )}
      >
        <BulkMandateColumn text={batchReference} title="Batch Reference" />
        <BulkMandateColumn
          text={new Date().toLocaleString().split(',').at(0)}
          title="Date Created"
        />
        <BulkMandateColumn
          text={formattedCurrency(Number(totalMandateAmount))}
          title="Total value of mandate"
        />
        <BulkMandateColumn
          text={String(totalMandateBeneficiaries)}
          title="Total Beneficiaries"
        />
      </Card>

      <Card
        className={combineClassName(
          cardStyles.mandate_table,
          `flex-column shadow px-0`
        )}
      >
        <div className="px-4 pt-4 pb-4 d-flex w-100 flex-wrap justify-content-between">
          <Text
            text={'Mandates'}
            size={6}
            bold
            color="var(--vfd-main-body-text)"
          />
          <div className="d-flex gap-2">
            <Button
              label="Submit Selected Batch"
              onClick={() => handleSubmitAllMandate(true)}
              type="primary"
              size="large"
              style={{ height: '100%' }}
              disabled={!batchList?.some((item) => item.isSelected)}
            />

            <div className="d-none d-md-block">
              <TextInput
                label="Email"
                placeholder="Search using email"
                id="global-search_0111"
                name="search_0111"
                type="search"
                value={searchableString}
                onChange={(_, value) => setSearchedString(value)}

                // errorText={validationResult.email}
                // valid={!validationResult.email}
                // isRequired
              />
            </div>
          </div>
        </div>

        <BulkCreationTable itemList={list} />
      </Card>
    </main>
  );
};

export default CreateBulkMandateDetails;
