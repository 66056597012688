import React from 'react';
import Text from '../text';
import { fontFamily } from '../../utils/fonts';
import emptyIcon from '../../assets/svgs/emptyItemIcon.svg';

const EmptyState = () => {
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img
          src={emptyIcon}
          alt="empty items icon"
          style={{ width: '24px', height: '24px' }}
        />
        <Text
          text="No records found"
          size={1}
          style={{ fontFamily: fontFamily.regular }}
          color="#000000"
          className="align-self-start mt-1"
        />
      </div>
    </div>
  );
};

export default EmptyState;
