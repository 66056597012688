import React from 'react';
import { combineClassName } from '../../utils/utilsMethods';
import styles from './card.module.css';

import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

interface ICardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
}

export default function Card({ ...props }: ICardProps) {
  return (
    <div
      style={props.style ? props.style : {}}
      className={combineClassName(styles.Card, props.className || ' ', 'card')}
      onClick={(e) => {
        if (props.onClick) props.onClick(e);
      }}
    >
      {props.children}
    </div>
  );
}
