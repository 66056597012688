/* eslint-disable react/react-in-jsx-scope */
import { CSSProperties, FC, useState, useEffect } from 'react';
import styles from './customSelect.module.css';
import Cancel from '../../assets/svgs/exit.svg';
// import ArrowDown from '../../assets/svgs/dropdown.svg';
import { Icon } from '../icons';

export interface ILoanHistoryFilter {
  onChange?: (text: string) => void;
  labelDescription?: string;
  label?: string;
  values?: string[];
  hideLabel?: boolean;
  selectedValue?: string;
  validation?: boolean;
  searchable?: boolean;
  errorMessage?: string;
  style?: CSSProperties;
  onInnerClose?: () => void;
  isRequired?: boolean;
  placeholder?: string;
}

const CustomSelect: FC<ILoanHistoryFilter> = ({
  label,
  values,
  onChange,
  labelDescription,
  hideLabel = false,
  selectedValue = '',
  validation = true,
  errorMessage,
  style,
  onInnerClose,
  searchable = false,
  isRequired,
  placeholder = '',
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const [items, setItems] = useState(() => values);
  const [touched, setTouched] = useState(false);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (selectedValue.length) {
      setCurrentValue(selectedValue);
      setSearch(selectedValue);
    }
  }, [selectedValue]);

  const handleOpen = (): void => {
    setOpen(!open);
  };
  const handleClose = (): void => {
    setOpen(false);
  };
  const handleValueChange = (value: string): void => {
    setCurrentValue(value);
    setSearch(value);
  };
  const handleChange = (value: string): void => {
    handleValueChange(value);
    if (onChange) onChange(value);
    handleClose();
  };

  const handleInnerClose = (): void => {
    setOpen(false);
    if (onInnerClose) onInnerClose();
  };

  useEffect(() => {
    let debounceFn: NodeJS.Timeout;
    if (values?.length) {
      if (search) {
        debounceFn = setTimeout(() => {
          const matchingItems = values.filter((item: string) =>
            item.toLowerCase().includes(search.toLowerCase())
          );
          setItems([...matchingItems]);
          if (currentValue !== matchingItems[0]) setOpen(true);
        }, 800);
      } else {
        setItems([...values]);
      }
    }
    return () => {
      clearTimeout(debounceFn);
    };
  }, [search, values]);
  return (
    <div
      onClick={handleOpen}
      style={{ cursor: 'pointer', ...style }}
      className={styles.containerClassName}
    >
      <div className={styles.SelectContainer}>
        {!hideLabel && (
          <div className={styles.label}>
            {label}
            {isRequired ? <span style={{ color: 'var(--error)' }}>*</span> : ''}
          </div>
        )}

        <div
          tabIndex={1}
          onBlur={() => setTouched(true)}
          className={styles.SelectLabelButton}
        >
          {!searchable ? (
            <div
              className={
                currentValue || selectedValue
                  ? styles.currentValue
                  : styles.placeholder
              }
            >
              {currentValue || selectedValue
                ? currentValue || selectedValue
                : label}
            </div>
          ) : (
            <input
              name="search"
              className={styles.search_input}
              value={search}
              autoComplete="off"
              placeholder={placeholder}
              onChange={(e) => setSearch(e.target.value)}
              defaultValue={selectedValue}
            />
          )}
          <div>
            {' '}
            {/* <img src={ArrowDown} alt="arrow dow" />{' '} */}
            <Icon name="caret_down" rotate={open ? 180 : 0} />
          </div>
        </div>

        {validation && touched ? (
          <span className={styles.valid_errormessage}>{errorMessage}</span>
        ) : null}

        {open && (
          <div className={styles.full_dropdown}>
            <div className={styles.description_head}>
              {labelDescription}
              <img
                src={Cancel}
                alt="cancel"
                onClick={() => handleInnerClose()}
                style={{ width: '24px', height: '24px' }}
              />
            </div>
            <div className={styles.dropdown_elements}>
              {items &&
                items.length > 0 &&
                items.map((value: string, index: number) => (
                  <div
                    className={styles.DropdownItem}
                    onClick={() => handleChange(value)}
                    key={index}
                  >
                    {value}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
