import { INetworkResponse } from './../interface/network';
import { requestMaker } from './request';
import endpoints from './endpoints';
import { ILoginData, ILoginResponse } from '../interface/auth';
import {
  ICreateMandateRequest,
  IGetBanks,
  IValidateAccountQuery,
  IValidateAccountResponse,
  IMandateResponse,
  IMandateDetailsContent,
  IMandateAnalyticsQuery,
  IMandateAnalyticsResponse,
  IMandateTransactionHistory,
  IMandateTransactionAnalyticsQuery,
  ITransactionAnalysisResponse,
  ICreateBulkMandateList,
} from '../interface/mandate';

async function fetchAllMandateDetails(
  reference: string
): Promise<IMandateDetailsContent> {
  return requestMaker({
    type: 'GET',
    route: endpoints.FETCH_MANDATE_DETAILS,
    isSecure: true,
    queryParams: {
      reference,
    },
  });
}

async function fetchAllMandateDetailsTransaction({
  reference = '',
  size = '10',
  page = '0',
  startDate = '',
  search = '',
  endDate = '',
}): Promise<IMandateTransactionHistory> {
  return requestMaker({
    type: 'GET',
    route: endpoints.FETCH_MANDATE_HISTORY,
    isSecure: true,
    queryParams: {
      reference,
      size,
      page,
      search,
      startDate,
      endDate,
    },
  });
}

async function fetchRecentTransaction(
  page: number,
  size: number,
  startDate: string,
  endDate: string
): Promise<IMandateTransactionHistory> {
  return requestMaker({
    type: 'GET',
    route: endpoints.FETCH_MANDATE_HISTORY,
    isSecure: true,
    queryParams: {
      page: `${page}`,
      size: `${size}`,
      startDate: startDate,
      endDate: endDate,
    },
  });
}

async function fetchAllMandate(
  page = 0,
  size = 10,
  startDate: string,
  endDate: string,
  isBulk: boolean,
  searchableString = ''
): Promise<IMandateResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.FETCH_ALL_MANDATE,
    isSecure: true,
    queryParams: {
      page: `${page}`,
      size: `${size}`,
      startDate: startDate,
      endDate: endDate,
      search: searchableString,
      isBulk: isBulk ? 'yes' : '',
    },
  });
}

async function login(data: ILoginData): Promise<ILoginResponse> {
  return requestMaker({
    type: 'POST',
    headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` },
    route: endpoints.AUTH,
    data,
  });
}

async function createMandate(
  data: ICreateMandateRequest
): Promise<ILoginResponse> {
  return requestMaker({
    type: 'POST',
    isSecure: true,
    route: endpoints.CREATE_MANDATE,
    data,
  });
}

async function getBanks(): Promise<IGetBanks> {
  return requestMaker({
    type: 'GET',
    isSecure: true,
    route: endpoints.GET_BANK,
  });
}

async function validateAccount(
  query: IValidateAccountQuery
): Promise<IValidateAccountResponse> {
  return requestMaker({
    type: 'GET',
    isSecure: true,
    route: endpoints.VALIDATE_ACCOUNT,
    queryParams: query,
  });
}

async function getMandateAnalytics(
  query?: IMandateAnalyticsQuery
): Promise<IMandateAnalyticsResponse> {
  return requestMaker({
    type: 'GET',
    isSecure: true,
    route: endpoints.MANDATE_ANALYTICS,
    queryParams: query,
    encodeQueryParams: false,
  });
}

async function uploadLogo(data: { file: string }): Promise<INetworkResponse> {
  return requestMaker({
    type: 'POST',
    isSecure: true,
    route: endpoints.UPLOAD,
    data,
  });
}

async function getMandateTransactionAnalytics(
  query: IMandateTransactionAnalyticsQuery
): Promise<ITransactionAnalysisResponse> {
  return requestMaker({
    type: 'GET',
    isSecure: true,
    route: endpoints.TRANSACTION_ANALYSIS,
    queryParams: query,
  });
}

async function triggerDebitRequest(query: {
  amount: string;
  narration: string;
  mandateReference: string;
  transactionReference: string;
}): Promise<INetworkResponse> {
  return requestMaker({
    type: 'POST',
    isSecure: true,
    route: endpoints.TRIGGER_DEBIT,
    data: query,
  });
}

export function uploadMandateInBulk(
  data: ICreateBulkMandateList[] | Record<string, unknown>[],
  batchReference: string
): Promise<INetworkResponse> {
  return requestMaker({
    type: 'POST',
    isSecure: true,
    route: endpoints.BULK_MANDATE_UPLOAD,
    data: {
      bulkRequest: [...data],
    },
    queryParams: { batchReference },
  });
}

const api = {
  login,
  createMandate,
  getBanks,
  validateAccount,
  fetchAllMandate,
  fetchAllMandateDetails,
  getMandateAnalytics,
  fetchAllMandateDetailsTransaction,
  uploadLogo,
  getMandateTransactionAnalytics,
  triggerDebitRequest,
  uploadMandateInBulk,
  fetchRecentTransaction,
};

export default api;
