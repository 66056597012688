import React from 'react';
import Routing from './routes';
import { Toaster } from 'react-hot-toast';
import Modals from './components/ModalWrapper/Modal';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routing />
      <Toaster />
      <Modals />
    </BrowserRouter>
  );
}

export default App;
