import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CustomSelect from '../customSelect';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  selectGlobalState,
  setBankList,
} from '../../redux/features/globalSlice';
import { useValidation } from '../../hooks/useValidation';
import { triggerMandateValidation } from '../../utils/validations';
import {
  IGetBanks,
  IValidateAccountResponse,
  modalTypes,
} from '../../interface/mandate';
import { v4 as uuid } from 'uuid';
import api from '../../network/api';
import { IError } from '../../interface/error';
import TextInput from '../textInput';
import { closeModal, openModal } from '../../redux/features/modalSlice';
import { MODAL_TYPES } from '../../utils/constants';
import useToast from '../../hooks/useToast';

const TriggerDebitModal = () => {
  const globalState = useAppSelector(selectGlobalState);
  const { modalProps } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    data,
    addData,
    // validated,
    validationResult,
    setValidationResult,
  } = useValidation(triggerMandateValidation);

  const [banks, setBanks] = useState<IGetBanks['data']['bank']>([]);
  const [bankValues, setBankValues] = useState<string[]>([]);
  useState<IValidateAccountResponse['data']>();
  const [loading, setIsLoading] = useState(false);

  const { amount, payerAccountNo } = modalProps ?? {};

  useEffect(() => {
    addData('amount', amount as string);
    addData('payerAccountNo', payerAccountNo as string);
  }, []);

  useEffect(() => {
    if (!globalState?.bankList?.length) {
      getBankList();
    } else {
      setBanks(globalState.bankList);
      setBankValues([...globalState.bankList.map((bank) => bank.name)]);
    }

    addData('reference', uuid());

    addData('receiverBank', '999999');
  }, []);

  useEffect(() => {
    if (globalState?.receiverAccountNo) {
      addData('receiverAccountNumber', globalState.receiverAccountNo);
    }
  }, [globalState]);

  async function getBankList() {
    try {
      const response = await api.getBanks();
      setBanks(response.data.bank);
      setBankValues([...response.data.bank.map((bank) => bank.name)]);
      dispatch(setBankList(response.data.bank));
    } catch (error) {
      const err = error as IError;
      toast.error(err.message || '');
    }
  }

  function onInputChange(name: string, value: string) {
    if (name === 'amount') {
      const newValue = value.replace(/,/g, '');
      addData(name, Number(newValue ?? 0).toLocaleString());
    } else {
      addData(name, value);
    }
  }

  async function validateAccountNumber() {
    try {
      const response = await api.validateAccount({
        accountNo: data.payerAccountNumber as string,
        bank: data.payerBank as string,
        transfer_type: data.payerBank === '999999' ? 'intra' : 'inter',
      });
      addData('payerBvn', response.data.bvn);
    } catch (error) {
      validationResult.payerAccountNumber = 'xxxxxxx';
      setValidationResult((prev) => {
        return {
          ...prev,
          payerAccountNumber: err.message || 'Account validation failed',
        };
      });
      const err = error as IError;
      toast.error(err.message || '');
    }
  }

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      if (
        data.payerBank &&
        data.payerAccountNumber &&
        (data.payerAccountNumber as string)?.length === 10
      ) {
        validateAccountNumber();
      }
    }, 800);

    return () => clearTimeout(debounceFn);
  }, [data.payerAccountNumber, data.payerBank]);

  const handleSelect = (bank: string): void => {
    const payerBank = banks.find((bankData) => bankData.name === bank);
    if (payerBank) addData('payerBank', payerBank?.code);
  };

  const handleSubmitDebit = async () => {
    try {
      setIsLoading(true);
      const response = await api.triggerDebitRequest({
        amount: (data?.amount as string)?.replace(/,/g, '') as string,
        narration: modalProps?.mandateStatusDescription
          ? (modalProps?.mandateStatusDescription as string)
          : (data.narration as unknown as string),
        mandateReference: modalProps?.mandateReference as string,
        transactionReference:
          (modalProps?.transactionReference as string) || uuid(),
      });
      if (response?.status === '99') {
        toast.error(response.message || '');
      } else {
        handleTriggerDebitSuccessModal(
          MODAL_TYPES.TRIGGER_DEBIT_SUCCESS_MODAL as modalTypes
        );
      }
    } catch (error) {
      const err = error as IError;
      toast.error(err.message || 'An error occured');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTriggerDebitSuccessModal = (
    modalType: keyof typeof MODAL_TYPES
  ) => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: modalType,
        modalProps: {
          showHeader: false,
        },
      })
    );
  };

  return (
    <form>
      <Modal.Body className="d-flex flex-column gap-2">
        <CustomSelect
          label="Payers Bank Name"
          searchable={false}
          labelDescription="Select payer's bank"
          placeholder="Select payer's bank"
          values={bankValues}
          onChange={handleSelect}
          selectedValue={modalProps?.payerBank as string}
          isRequired
          // disabled={true}
          // hideLabel={false}
        />

        <TextInput
          label="Payers Account Number"
          placeholder="Enter payer's account number"
          id="payerAccountNumber"
          name="payerAccountNumber"
          max={10}
          type="text"
          onChange={onInputChange}
          disabled={true}
          errorText={validationResult.payerAccountNo}
          valid={!validationResult.payerAccountNo}
          defaultValue={(data?.payerAccountNo as string) ?? ''}
          isRequired
        />

        <TextInput
          label="Amount"
          placeholder="Enter amount"
          id="amount"
          name="amount"
          type="text"
          defaultValue={data?.amount as string}
          onChange={onInputChange}
          errorText={validationResult.amount}
          valid={!validationResult.amount}
          isRequired
        />

        {!modalProps?.mandateStatusDescription && (
          <TextInput
            label="Narration"
            placeholder="Enter narration"
            id="narration"
            name="narration"
            type="text"
            defaultValue={data?.narration as string}
            onChange={onInputChange}
            errorText={validationResult.narration}
            valid={!validationResult.narration}
            isRequired
          />
        )}
      </Modal.Body>
      <Modal.Footer bsPrefix="border-0 gap-3 d-flex justify-content-between align-items-center p-3">
        <Button
          className={`btn border d-flex gap-2 w-50 text-center  py-2 justify-content-center px-3 align-items-center`}
          variant="outline"
          onClick={() => {
            dispatch(closeModal());
          }}
          disabled={loading}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            handleSubmitDebit();
          }}
          disabled={loading || !(modalProps?.payerBank as string)?.length}
          variant="primary"
          className={`btn d-flex gap-2 w-50 text-center justify-content-center py-2 px-3 align-items-center`}
        >
          Submit
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default TriggerDebitModal;
