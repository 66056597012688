import React, { FC, useState } from 'react';
import { combineClassName } from '../../utils/utilsMethods';
import styles from './tab.module.css';

interface IProps {
  navs: Array<string>;
  setSelectedIndex?: (val: number) => void;
}
const Tab: FC<IProps> = ({ navs, setSelectedIndex }) => {
  const [index, setIndex] = useState<number>(0);

  function handleSelect(i: number) {
    setIndex(i);
    if (setSelectedIndex) setSelectedIndex(i);
  }

  return (
    <ul
      className="nav nav-underline"
      style={{ borderBottom: 'solid 2px #EAECF0' }}
    >
      {navs.map((nav, i) => (
        <li
          key={i + nav}
          className={combineClassName(
            styles.tabs,
            'nav-item',
            index == i ? styles.active : ''
          )}
        >
          <button
            className={combineClassName(
              'btn btn-link nav-link',
              styles.btn_link
            )}
            aria-current="page"
            onClick={() => handleSelect(i)}
          >
            {/* <Icon
                  name='overview'
                  color={
                    detailType === 'overview' ? '#0052ff' : '#999CA0'
                  }
                />{' '} */}
            <span className="">{nav}</span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Tab;
