import React, { CSSProperties, FC } from 'react';

const BorderLine: FC<{ style?: CSSProperties }> = ({ ...props }) => {
  return (
    <div
      className={`w-100 mt-3`}
      style={{ borderBottom: 'solid 2px #EAECF0', ...props.style }}
    />
  );
};

export default BorderLine;
