import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface IModalProps extends Record<string | never, string | unknown> {
  size?: 'sm' | 'lg' | 'xl';
  showHeader?: boolean;
}

interface IModalState {
  isOpen: boolean;
  modalType: string;
  modalProps: IModalProps;
}

const initialState: IModalState = {
  isOpen: false,
  modalType: '',
  modalProps: {
    showHeader: true,
    size: 'sm',
  },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, actions: PayloadAction<IModalState>) {
      state.isOpen = actions.payload.isOpen;
      state.modalProps = actions.payload.modalProps;
      state.modalType = actions.payload.modalType;
    },
    closeModal(state) {
      state.isOpen = false;
      state.modalProps = {};
      state.modalType = '';
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
