import React from 'react';
import { Oval } from 'react-loader-spinner';

const LoadingState = () => {
  return (
    <div
      style={{
        height: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Oval
        visible={true}
        height="80"
        secondaryColor="#F2F4F7"
        width="80"
        color="#1570EF"
        ariaLabel="oval-loading"
      />
    </div>
  );
};

export default LoadingState;
