import React from 'react';
import Text from '../../../components/text';
import { addEllipsisToText } from '../../../utils/utilsMethods';

interface IProps {
  title: string;
  text?: string;
  textColor?: string;
  badge?: React.ReactNode;
}

const BulkMandateColumn = ({
  title,
  text,
  textColor = 'var(--vfd-main-body-text)',
  badge,
}: IProps) => {
  return (
    <div className="gap-3">
      <Text text={title} size={2} color="var(--Gray-600, #475467)" />

      {text && (
        <>
          <Text
            text={addEllipsisToText(text, 10)}
            style={{ fontWeight: '600', textTransform: 'capitalize' }}
            size={2}
            className="mt-2 mb-2 d-block d-md-none"
            color={textColor}
          />
          <Text
            text={text}
            style={{ fontWeight: '600', textTransform: 'capitalize' }}
            size={2}
            className="mt-2 mb-2 d-none d-md-block"
            color={textColor}
          />
        </>
      )}
      <div className="mt-2 mb-2">{badge && badge}</div>
    </div>
  );
};

export default BulkMandateColumn;
