import styles from './checkbox.module.css';
import { combineClassName } from '../../utils/utilsMethods';
import React, { DetailedHTMLProps, HTMLAttributes, useState } from 'react';

interface ICheckBoxProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  label: string;
  handleCheck?: (val: boolean) => void;
}

export default function CheckBox({ label, ...props }: ICheckBoxProps) {
  const [check, setCheck] = useState(false);
  return (
    <div className="form-check" {...props}>
      <input
        className={combineClassName(
          styles.form_check_input,
          'form-check-input'
        )}
        type="checkbox"
        id={props.id?.toString()}
        onChange={(e) => {
          setCheck(e.target.checked);
          if (props.handleCheck) props.handleCheck(e.target.checked);
        }}
        checked={check}
      />
      <label
        className={combineClassName(
          styles.form_check_label,
          'form-check-label',
          check ? styles.label_check : ''
        )}
        htmlFor={props.id?.toString()}
      >
        {label}
      </label>
    </div>
  );
}
