import { lazy } from 'react';
import Home from '../Screens/Home';
import Reports from '../Screens/Reports';
import {
  HOME,
  MANDATE,
  MANDATE_BULKVIEW,
  MANDATE_BULKVIEW_DETAILS,
  MANDATE_SINGLEVIEW,
  CREATE_MANDATE,
  CREATE_BULK_MANDATE,
  MANDATE_SUCCESS,
  PREVIEW_MANDATE,
  REPORTS,
  SETTINGS,
  VIEW_MANDATE_FROM_BULK_CREATION,
} from './navigations';
import Mandate from '../Screens/Mandate';
import SingleMandate from '../Screens/Mandate/Single/SingleView/index';
import BulkView from '../Screens/Mandate/BulkView/Bulkview_Screen';
import BulkViewDetailsScreen from '../Screens/Mandate/BulkView/Bulkview_Details_Screen';
import Settings from '../Screens/Settings/index';
import CreateBulkMandateDetails from '../Screens/CreateMandate/components/createBulkMandateDetails';
import ViewSingleMandateFromBulk from '../Screens/CreateMandate/components/viewSingleMandateFromBulk';

const CreateMandate = lazy(() => import('../Screens/CreateMandate'));
const PreviewSingleMandate = lazy(
  () => import('../Screens/CreateMandate/previewSingleMandate')
);
const MandateSuccess = lazy(
  () => import('../Screens/CreateMandate/createMandateSuccess')
);

const privateRoutes = [
  { path: HOME, element: Home },
  { path: REPORTS, element: Reports },
  { path: CREATE_MANDATE, element: CreateMandate },
  { path: CREATE_BULK_MANDATE, element: CreateBulkMandateDetails },
  { path: VIEW_MANDATE_FROM_BULK_CREATION, element: ViewSingleMandateFromBulk },
  { path: PREVIEW_MANDATE, element: PreviewSingleMandate },
  { path: MANDATE_SUCCESS, element: MandateSuccess },
  { path: MANDATE, element: Mandate },
  { path: MANDATE_SINGLEVIEW, element: SingleMandate },
  { path: MANDATE_BULKVIEW, element: BulkView },
  { path: MANDATE_BULKVIEW_DETAILS, element: BulkViewDetailsScreen },
  { path: SETTINGS, element: Settings },
];

export default privateRoutes;
