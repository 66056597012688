import { useEffect, useRef } from 'react';

const useClickAway = <T extends HTMLElement>(handler: () => void) => {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    const onClickAway = (event: MouseEvent) => {
      if (ref.current && !ref.current?.contains(event.target as Node))
        handler();
    };

    document.addEventListener('click', onClickAway);

    return () => {
      document.removeEventListener('click', onClickAway);
    };
  }, []);

  return { ref };
};

export default useClickAway;
