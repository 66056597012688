/* eslint-disable @typescript-eslint/no-unused-vars */
// import { Suspense } from "react";
import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PublicContainer from '../Container/publicContainer';
import publicRoutes from './public';
import Main from '../Container/main';
import privateRoutes from './private';
import Loader from '../components/Loader/Loader';

const Routing = () => {
  return (
    <Suspense
      fallback={
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
          <Loader text="Loading..." />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Main />}>
          {privateRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<route.element />}
                key={index}
              />
            );
          })}
        </Route>

        <Route path="/" element={<PublicContainer />}>
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<route.element />}
                key={index}
              />
            );
          })}
        </Route>

        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Suspense>
  );
};

export default Routing;
