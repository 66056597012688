import React from 'react';
import BackButton from '../../../components/backButton';
import LabelAndValue from '../../../components/LabelAndValue';
import Text from '../../../components/text';
import Card from '../../../components/card';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { selectMandateState } from '../../../redux/features/mandateSlice';
import { selectGlobalState } from '../../../redux/features/globalSlice';
import styles from '../mandate.module.css';
import { fontFamily } from '../../../utils/fonts';
import DetailsCard from '../../../components/detailsCard';
import {
  calcDuration,
  combineClassName,
  formatMandateDate,
  formattedCurrency,
  frequencyInWords,
} from '../../../utils/utilsMethods';
import BorderLine from '../../../components/borderLine';
import Button from '../../../components/button';
import { openModal } from '../../../redux/features/modalSlice';
import { MODAL_TYPES } from '../../../utils/constants';
import { frequencyType } from '../../../interface/mandate';

const ViewSingleMandateFromBulk = () => {
  const navigate = useNavigate();
  function handleGoBack() {
    navigate(-1);
  }
  const { mandateId: idToUse } = useParams();
  const {
    bulkMandate: { batchList },
  } = useAppSelector(selectMandateState);

  const { receiverAccountNo, receiverName, bankList } =
    useAppSelector(selectGlobalState);

  const dispatch = useAppDispatch();

  const details = batchList.find((item) => item.reference == idToUse);

  const userBank = bankList?.find(
    (bank) =>
      bank.code == String(details?.payerBank) ||
      bank.name == String(details?.payerBank)
  )?.name;

  const handleOpenEditMandateModal = () => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: MODAL_TYPES.EDIT_TRANSACTION_MODAL,
        modalProps: {
          title: 'Edit Details',
          size: 'lg',
          details,
        },
      })
    );
  };

  return (
    <div className={combineClassName('container', styles.container)}>
      <BackButton />

      {idToUse && (
        <Card className={styles.card_wrapper}>
          <div className="d-flex align-items-center" style={{ height: 40 }}>
            <Text
              size={3}
              bold
              text="Preview details"
              color="var(--SPN-900)"
              style={{ fontFamily: fontFamily.extraBold }}
            />
          </div>

          <DetailsCard>
            <div className="mb-3">
              <Text
                size={4}
                color="var(--blue-600)"
                bold
                text="Mandate details"
              />
            </div>
            <div className="row">
              <div className="col-md-3 mb-3">
                <LabelAndValue
                  label="Mandate Amount"
                  value={formattedCurrency(Number(details?.amount))}
                />
              </div>
              <div className="col-md-3">
                <LabelAndValue
                  label="Duration"
                  value={calcDuration(
                    String(details?.startDate),
                    String(details?.endDate)
                  )}
                />
              </div>
              <div className="col-md-3">
                <LabelAndValue
                  label="Frequency"
                  value={frequencyInWords(details?.frequency as frequencyType)}
                  valueStyle={{ textTransform: 'capitalize' }}
                />
              </div>
              {/* <div className="col-md-3">
              <LabelAndValue
                label="Date Created"
                value={mandateDetails.createdAt}
              />
            </div> */}
              <div className="col-md-3">
                <LabelAndValue
                  label="Start date"
                  value={formatMandateDate(
                    String(details?.startDate),
                    'MMM dd, yyyy'
                  )}
                />
              </div>
              <div className="col-md-3">
                <LabelAndValue
                  label="End date"
                  value={formatMandateDate(
                    String(details?.endDate),
                    'MMM dd, yyyy'
                  )}
                />
              </div>
            </div>
          </DetailsCard>

          <DetailsCard>
            <div className="mb-3">
              <Text
                size={4}
                color="var(--blue-600)"
                bold
                text="Payer's details"
              />
            </div>
            <div className="row">
              <div className="col-md-3 mb-3">
                <LabelAndValue
                  label="Email Address"
                  value={String(details?.payerEmailAddress)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <LabelAndValue
                  label="Account Number"
                  value={String(details?.payerAccountNumber)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <LabelAndValue label="Bank Name" value={String(userBank)} />
              </div>
              <div className="col-md-3 mb-3">
                <LabelAndValue
                  label="Phone Number"
                  value={String(details?.payerPhone)}
                />
              </div>
              <div className="col-md-4 mb-3">
                <LabelAndValue
                  label="Address"
                  value={String(details?.payerAddress)}
                />
              </div>
            </div>
          </DetailsCard>

          <DetailsCard>
            <div className="mb-3">
              <Text
                size={4}
                color="var(--blue-600)"
                bold
                text="Receiver's details"
              />
            </div>
            <div className="row">
              <div className="col-md-3 mb-3">
                <LabelAndValue
                  label="Receiver’s Bank"
                  valueIconName="bank"
                  value="VFD MFB"
                />
              </div>
              <div className="col-md-3 mb-3">
                <LabelAndValue
                  label="Account Number"
                  value={receiverAccountNo ?? ''}
                />
              </div>
              <div className="col-md-3 mb-3">
                <LabelAndValue
                  label="Account Name"
                  value={receiverName ?? ''}
                />
              </div>
            </div>
          </DetailsCard>

          <BorderLine />

          <div className="d-flex justify-content-end gap-3 align-items-center mt-2">
            <div style={{ width: 151 }}>
              <Button
                style={{ borderColor: '#D0D5DD' }}
                type="outline"
                label="Back"
                onClick={() => handleGoBack()}
              />
            </div>
            <div style={{ width: 200 }}>
              <Button
                type="primary"
                onClick={() => handleOpenEditMandateModal()}
                label="Edit Details"
                // disabled={true}
                // loading={loading}
                // disabled={!agreeTerms}
              />
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default ViewSingleMandateFromBulk;
