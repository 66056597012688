import React from 'react';
import styles from '../../mandate.module.css';
import { Icon } from '../../../../components/icons';
import Text from '../../../../components/text';
import { Link, useLocation } from 'react-router-dom';
import { ISelectableMandateList } from '../../../../interface/mandate';
import MandatePagination from '../../components/MandatePagination';
import {
  formatMandateDate,
  formattedCurrency,
} from '../../../../utils/utilsMethods';
import {
  MANDATE_CREATION_STATUS,
  MANDATE_STATUS,
} from '../../components/SingleMandateTable';
import Loader from '../../../../components/Loader/Loader';
import EmptyState from '../../../../components/EmptyState/EmptyState';

interface IProps {
  itemList?: ISelectableMandateList[];
  loading: boolean;
  totalPageAndElement: {
    totalElements: number;
    totalPages: number;
  };
  gotoNextPage: () => void;
  gotoPreviousPage: () => void;
  gotoPage: (page: number) => void;
  currentPage: number;
}

const BulkMandateTable = ({
  itemList,
  loading,
  totalPageAndElement,
  gotoNextPage,
  gotoPage,
  gotoPreviousPage,
  currentPage,
}: IProps) => {
  const { pathname } = useLocation();

  return (
    <>
      <div className="table-responsive">
        <table className="table ">
          <thead>
            <tr className={styles.table_header}>
              <th className="ps-4" scope="col ">
                <div className="d-flex align-items-center">
                  {/* <input type="checkbox" disabled={!itemList?.length} /> */}
                  <Text
                    text={'Ref'}
                    size={2}
                    style={{ width: 'max-content' }}
                    className="ps-2"
                    color={'var(--vfd-main-body-text)'}
                  />
                  <span className="ps-2">
                    <Icon name="downward_arrow" />
                  </span>
                </div>
              </th>
              <th scope="col">
                <Text
                  text={'Customer Name'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Account Number'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Creation Status'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Mandate Amount'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                <Text
                  text={'Mandate Status'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Date Created'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Actions'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2 invisible "
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
            </tr>
          </thead>

          {/* Table body starts here */}
          <tbody>
            {loading ? (
              <tr style={{ textAlign: 'center' }}>
                <td colSpan={8} style={{ padding: '20px 0' }}>
                  <Loader />
                </td>
              </tr>
            ) : itemList?.length ? (
              itemList.map(
                (
                  {
                    amount,
                    receiverAccountNo,
                    receiverAccountName,
                    dateCreated,
                    mandateReference,
                    status,
                    mandateStatusDescription,
                  },
                  index
                ) => (
                  <tr className="" key={index}>
                    <td className="ps-4 py-3" scope="row">
                      <div className="d-flex align-items-center">
                        <Text
                          text={mandateReference}
                          size={2}
                          style={{ width: 'max-content' }}
                          className="ps-2"
                          color={'var(--vfd-main-body-text)'}
                        />
                      </div>
                    </td>
                    <td className="py-3">
                      <Text
                        text={receiverAccountName}
                        size={2}
                        style={{ width: 'max-content' }}
                        className="ps-2"
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>
                    <td className="py-3">
                      {' '}
                      <Text
                        text={receiverAccountNo}
                        size={2}
                        style={{ width: 'max-content' }}
                        className="ps-2"
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>
                    <td className="py-3">
                      {
                        MANDATE_CREATION_STATUS[
                          status as keyof typeof MANDATE_CREATION_STATUS
                        ]
                      }
                    </td>
                    <td className="py-3">
                      {' '}
                      <Text
                        text={formattedCurrency(Number(amount))}
                        size={2}
                        style={{ width: 'max-content' }}
                        className="ps-2"
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>

                    <td className="py-3">
                      {!mandateStatusDescription ||
                      !mandateStatusDescription.length
                        ? MANDATE_STATUS['Bank Initiated']
                        : MANDATE_STATUS[
                            mandateStatusDescription as keyof typeof MANDATE_STATUS
                          ]}
                    </td>
                    <td className="py-3">
                      <Text
                        text={formatMandateDate(dateCreated)}
                        size={2}
                        style={{ width: 'max-content' }}
                        className="ps-2"
                        color={'var(--vfd-main-body-text)'}
                      />
                    </td>
                    <td className="">
                      <Link
                        to={pathname + '/' + mandateReference}
                        className="btn"
                      >
                        <Text
                          text={'View'}
                          size={2}
                          style={{ width: 'max-content' }}
                          className="ps-2"
                          color={'var(--blue-600)'}
                        />
                      </Link>
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr style={{ textAlign: 'center' }}>
                <td colSpan={8} style={{ padding: '20px 0' }}>
                  <EmptyState />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <MandatePagination
        totalPages={totalPageAndElement?.totalPages}
        gotoNextPage={gotoNextPage}
        gotoPreviousPage={gotoPreviousPage}
        gotoPage={gotoPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default BulkMandateTable;
