import React from 'react';
import BulkMandateColumn from './BulkMandateColumn';
import Text from '../../../components/text';
import { useNavigate } from 'react-router-dom';
import { IBatchMandate } from '../../../interface/mandate';
import {
  formatMandateDate,
  formattedCurrency,
} from '../../../utils/utilsMethods';

const BulkMandateRow = ({
  item: { batchReference, dateCreated, totalMandateAmount, totalMandates },
}: {
  item: IBatchMandate;
}) => {
  const navigate = useNavigate();
  const gotoBulkMandateList = (reference: string) => {
    navigate('bulkview/' + reference + '?queryType=Bulk');
  };

  return (
    <div className="d-flex justify-content-between border-top border-bottom pt-4 pb-4 ps-4 pe-4">
      <div className="col-3 d-flex justify-content-start align-items-center">
        <BulkMandateColumn text={batchReference} title="Batch Reference" />
      </div>
      <div className="col-3 d-flex justify-content-start align-items-center">
        <BulkMandateColumn
          text={formatMandateDate(dateCreated, 'dd/MM/yyyy')}
          title="Date Created"
        />
      </div>
      <div className="col-3 d-md-flex d-none justify-content-start align-items-center">
        <BulkMandateColumn
          text={formattedCurrency(Number(totalMandateAmount))}
          title="Total value of mandate"
        />
      </div>
      <div className="col-2 d-flex justify-content-start align-items-center">
        <BulkMandateColumn
          text={String(totalMandates)}
          title="Total Beneficiaries"
        />
      </div>

      <div className="col-1 d-flex justify-content-center align-items-center">
        <Text
          text={'View'}
          bold
          size={3}
          color={'var(--blue-600)'}
          className="btn"
          onClick={() => gotoBulkMandateList(batchReference)}
        />
      </div>
    </div>
  );
};

export default BulkMandateRow;
