import React from 'react';
import Text from '../../../../components/text';
import { Icon } from '../../../../components/icons';
import styles from './singleview.module.css';
// import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/redux';
import { MODAL_TYPES } from '../../../../utils/constants';
import { openModal } from '../../../../redux/features/modalSlice';
import { Link } from 'react-router-dom';
import { IMandateHistoryContent } from '../../../../interface/mandate';
import {
  addEllipsisToText,
  formatMandateDate,
  formattedCurrency,
} from '../../../../utils/utilsMethods';

interface IProps {
  itemList: IMandateHistoryContent[];
}

export const TRANSACTION_STATUS = {
  '00': (
    <span className={`${styles.table_badge} ${styles.badge_active}`}>
      Successful
    </span>
  ),
  '09': (
    <span className={`${styles.table_badge} ${styles.badge_inactive}`}>
      Failed
    </span>
  ),
};

const TransactionHistoryTable = ({ itemList }: IProps) => {
  const dispatch = useAppDispatch();
  const handleOpenTransactionReceipt = (
    modalType: string,
    transactionType: string = 'success',
    transactionDetails: IMandateHistoryContent
  ) => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: modalType,
        modalProps: {
          title: 'Transaction Receipt',
          size: 'lg',
          transactionType,
          transactionDetails: { ...transactionDetails },
        },
      })
    );
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr className={styles.table_header}>
              <th className="ps-4" scope="col ">
                <div className="d-flex align-items-center">
                  <Text
                    text={'Ref'}
                    size={2}
                    className="ps-2"
                    color={'var(--vfd-main-body-text)'}
                  />
                  <span className="ps-2">
                    <Icon name="downward_arrow" />
                  </span>
                </div>
              </th>
              <th scope="col">
                <Text
                  text={'Source account'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Beneficiary Name'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Beneficiary Account'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Amount'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col ">
                <Text
                  text={'Status'}
                  size={2}
                  className="d-inline"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="col">
                {' '}
                <Text
                  text={'Date'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
              <th scope="">
                {' '}
                <Text
                  text={'Actions'}
                  size={2}
                  style={{ width: 'max-content' }}
                  className="ps-2 invisible"
                  color={'var(--vfd-main-body-text)'}
                />
              </th>
            </tr>
          </thead>

          <tbody>
            {itemList?.map((trxn, index) => {
              const {
                amount,
                mandateReference,
                toAccountNo,
                time,
                walletName,
                fromAccountNo,
                transactionResponse,
              } = trxn;
              return (
                <tr className="" key={index}>
                  <td className="ps-4 py-3" scope="row">
                    <div className="d-flex align-items-center">
                      <Text
                        text={addEllipsisToText(mandateReference, 10)}
                        size={2}
                        style={{ width: 'max-content' }}
                        className="ps-2"
                        color={'var(--vfd-main-body-text)'}
                      />
                    </div>
                  </td>
                  <td className="py-3">
                    {' '}
                    <Text
                      text={fromAccountNo}
                      size={2}
                      style={{ width: 'max-content' }}
                      className="ps-2"
                      color={'var(--vfd-main-body-text)'}
                    />
                  </td>
                  <td className="py-3">
                    {' '}
                    <Text
                      text={walletName}
                      size={2}
                      style={{ width: 'max-content' }}
                      className="ps-2"
                      color={'var(--vfd-main-body-text)'}
                    />
                  </td>
                  <td className="py-3">
                    {' '}
                    <Text
                      text={toAccountNo}
                      size={2}
                      style={{ width: 'max-content' }}
                      className="ps-2"
                      color={'var(--vfd-main-body-text)'}
                    />
                  </td>
                  <td className="py-3">
                    <Text
                      text={formattedCurrency(Number(amount))}
                      size={2}
                      style={{ width: 'max-content' }}
                      className="ps-2"
                      color={'var(--vfd-main-body-text)'}
                    />
                  </td>
                  <td className="py-3">
                    {
                      TRANSACTION_STATUS[
                        transactionResponse as keyof typeof TRANSACTION_STATUS
                      ]
                    }
                  </td>

                  <td className="py-3">
                    <Text
                      text={formatMandateDate(time)}
                      size={2}
                      style={{ width: 'max-content' }}
                      className="ps-2"
                      color={'var(--vfd-main-body-text)'}
                    />
                  </td>
                  <td className="py-3 text-center">
                    <Link
                      to={'#'}
                      onClick={() =>
                        handleOpenTransactionReceipt(
                          MODAL_TYPES.TRANSACTION_RECEIPT,
                          transactionResponse == '00' ? 'success' : 'failed',
                          trxn
                        )
                      }
                      className=""
                      style={{ textDecoration: 'none' }}
                    >
                      <Text
                        text={'View'}
                        size={2}
                        style={{ width: 'max-content' }}
                        className="px-2"
                        color={'var(--blue-600)'}
                      />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TransactionHistoryTable;
