import React, { useState } from 'react';
import TwoTabsButton from '../../components/TwoTabsButton';
import { Icon } from '../../../../components/icons';
import styles from '../../mandate.module.css';
import {
  MANDATE_DETAILS,
  TRANSACTION_HISTORY,
} from '../../../../utils/constants';
import BulkMandateDetailsCard from '../../components/BulkMandateDetailsCard';
import BulkTransactionHistory from './BulkTransactionHistory';

const index = () => {
  const [selectedBtn, setSelectedBtn] = useState(MANDATE_DETAILS);

  const changeTab = (tab: string) => {
    setSelectedBtn(tab);
  };
  return (
    <main className="container pb-5">
      <header className="pt-3 pb-2">
        <button
          onClick={() => window.history.back()}
          className={`${styles.mandate_btn} d-flex gap-2 px-3 align-items-center`}
        >
          <Icon name="back_arrow" /> <span className="">Back</span>
        </button>
        <div className="d-flex pt-4 pb-4 justify-content-between align-items-center flex-wrap gap-2 gap-lg-0">
          <TwoTabsButton
            changeTab={changeTab}
            leftBtn={MANDATE_DETAILS}
            rightBtn={TRANSACTION_HISTORY}
            activeTab={selectedBtn}
          />
        </div>
      </header>

      {selectedBtn === MANDATE_DETAILS && <BulkMandateDetailsCard />}

      {selectedBtn === TRANSACTION_HISTORY && <BulkTransactionHistory />}
    </main>
  );
};

export default index;
