import React, { useEffect } from 'react';
import {
  NavLink,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styles from './container.module.css';
import {
  base64PreferredPrefix,
  combineClassName,
  getInitials,
  updateFavIcon,
} from '../utils/utilsMethods';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { logout, selectAuthState } from '../redux/features/authSlice';
import {
  HOME,
  LANDING,
  REPORTS,
  MANDATE,
  SETTINGS,
} from '../routes/navigations';
import Dropdown, { IDropdownItem } from '../components/dropdown';
import Text from '../components/text';
import {
  resetGlobalState,
  selectGlobalState,
} from '../redux/features/globalSlice';
import useToast from '../hooks/useToast';
import { jwtDecode } from 'jwt-decode';
import { getItem } from '../utils/storage';
import { storageKey } from '../utils/constants';

const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authState = useAppSelector(selectAuthState);
  const globalState = useAppSelector(selectGlobalState);
  const logo = useAppSelector(selectGlobalState).companyLogo;
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [showMenu, setShowMenu] = React.useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const decoded = jwtDecode(getItem(storageKey.AUTH_TOKEN) as string);
      if (decoded?.exp) {
        const countDownDate = new Date(decoded?.exp * 1000).getTime();
        const now = new Date().getTime();
        const diff = countDownDate - now;
        const minutes = Math.floor(diff / (1000 * 60));
        // const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        if (minutes === 5 || minutes < 5) {
          clearInterval(timer);
          toast.error('Your session has expired, please sign in again');
          setTimeout(() => {
            logoutAction({ redirect: true });
          }, 1000);
        }
      }
    }, 60000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logo) {
      // const img = new Image();
      updateFavIcon(logo);
    }
  }, [logo]);
  if (!authState.authenticated) {
    return <Navigate to={LANDING} replace />;
  }

  function toggleMenu() {
    setShowMenu((prev) => !prev);
  }

  const navs = [
    {
      name: 'Home',
      link: HOME,
    },
    {
      name: 'Mandates',
      link: MANDATE,
    },
    {
      name: 'Reports',
      link: REPORTS,
    },
    {
      name: 'Settings',
      link: SETTINGS,
    },
  ];

  const logoutAction = (data?: { redirect: boolean }) => {
    if (data?.redirect) {
      dispatch(logout());
      dispatch(resetGlobalState());
      navigate(LANDING, { state: { from: location.pathname } });
    } else {
      dispatch(logout());
      // dispatch(resetGlobalState());
    }
  };

  const selectItem = (item: IDropdownItem) => {
    if (item.value === 'settings') {
      navigate(SETTINGS);
    } else {
      logoutAction();
    }
  };

  return (
    <div>
      <header className={styles.header}>
        <nav className={styles.navbar}>
          <div style={{ display: 'flex', alignItems: 'center', height: 40 }}>
            {globalState.companyLogo && (
              <img
                src={base64PreferredPrefix + globalState.companyLogo}
                className={styles.nav_logo}
                alt="Logo"
              />
            )}
          </div>
          <ul
            className={combineClassName(
              styles.nav_menu,
              showMenu ? styles.active : ''
            )}
          >
            {navs.map((nav) => (
              <li className={styles.nav_item} key={nav.name}>
                <NavLink
                  to={nav.link}
                  className={({ isActive }) =>
                    isActive
                      ? combineClassName(
                          styles.nav_link,
                          styles.nav_link_active
                        )
                      : styles.nav_link
                  }
                  onClick={() => toggleMenu()}
                >
                  {nav.name}
                </NavLink>
              </li>
            ))}
          </ul>

          <div className={styles.profile_wrapper}>
            <div className={combineClassName(styles.profile)}>
              {getInitials(globalState.receiverName ?? '')}
            </div>
            <div className="me-2">
              <Dropdown
                style={{ border: 'none', boxShadow: 'none' }}
                items={[
                  { label: 'Settings', value: 'settings' },
                  { label: 'Logout', value: 'logout' },
                ]}
                label={
                  <div className="d-lg-flex d-none flex-column justify-content-between">
                    <Text
                      text={`${globalState.receiverName || '-'}`}
                      size={3}
                      style={{ fontWeight: 500 }}
                      color="#333754"
                    />
                    <Text text={globalState.email || '-'} size={2} />
                  </div>
                }
                onSelectItem={selectItem}
              />
            </div>
            {/* <Icon name='caret_down' /> */}
          </div>
          <div
            onClick={toggleMenu}
            className={combineClassName(
              styles.hamburger,
              showMenu ? styles.active : ''
            )}
          >
            <span className={styles.bar} />
            <span className={styles.bar} />
            <span className={styles.bar} />
          </div>
        </nav>
      </header>
      <div className={styles.main}>
        <Outlet />
      </div>
    </div>
  );
};

export default Main;
