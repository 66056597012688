import React from 'react';
import BulkMandateRow from './components/BulkMandateRow';
import MandatePagination from './components/MandatePagination';
import useFetchMandate from '../../hooks/useFetchMandate';
import { useAppSelector } from '../../hooks/redux';
import { selectMandateState } from '../../redux/features/mandateSlice';
import Loader from '../../components/Loader/Loader';

const BulkMandateContainer = () => {
  const {
    loading: loadingMandates,
    batchList,
    totalPages,
  } = useAppSelector(selectMandateState);

  const { gotoNextPage, gotoPreviousPage, gotoPage, currentPage } =
    useFetchMandate();

  return (
    <>
      {!loadingMandates ? (
        batchList.length ? (
          batchList?.map((item, index) => (
            <BulkMandateRow item={item} key={index.toString()} />
          ))
        ) : (
          <div className="text-center border-top">
            You don&apos;t have any bulk mandate
          </div>
        )
      ) : (
        <div className="d-flex justify-content-center w-100 align-items-center">
          <div style={{ padding: '20px 0' }} className="w-100">
            <Loader />
          </div>
        </div>
      )}
      {/* Pagination section */}
      <MandatePagination
        totalPages={totalPages as number}
        gotoNextPage={gotoNextPage}
        gotoPreviousPage={gotoPreviousPage}
        gotoPage={gotoPage}
        loading={loadingMandates}
        currentPage={currentPage}
      />
    </>
  );
};

export default BulkMandateContainer;
