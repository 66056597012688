import React from 'react';
import Text from '../../../components/text';
import styles from '../mandate.module.css';

interface IProps {
  title: string;
  number: string;
}

const MandateCards = ({ title, number = '400' }: IProps) => {
  return (
    <div className={`d-flex flex-column flex-grow-1 justify-content-between`}>
      <Text text={title} size={5} color="var(--vfd-main-body-text)" />
      <Text
        text={number}
        className={styles.mandate_card_numbers}
        color="var(--blue-600)"
      />
    </div>
  );
};

export default MandateCards;
