import Text from '../../../components/text';
import React, { FC } from 'react';
import styles from '../home.module.css';

interface IProps {
  label: string;
  value: number | string;
  percentage: number;
}
const CollectionTab: FC<IProps> = ({ label, value, percentage }) => {
  return (
    <div className={styles.collection_card_wrapper}>
      <div
        className={styles.dot}
        style={{
          backgroundColor: percentage >= 0 ? 'var(--green)' : 'var(--error)',
        }}
      />
      <div>
        <Text
          text={label}
          size={1}
          style={{ fontSize: 12 }}
          color="var(--Light-Trunks)"
        />
        <div className="d-flex gap-1">
          <Text text={value.toString()} size={1} />
          <Text
            style={{ fontSize: 12 }}
            text={`${percentage >= 0 ? '+' : ''}${percentage}%`}
            color={
              percentage >= 0 ? 'var(--Supportive-Roshi-100)' : 'var(--error)'
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CollectionTab;
